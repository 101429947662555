<template>
<div class="">
  <v-btn
    @click="$emit('option', {key: 'back', body: {}})"
    small
    outlined
    :class="{'black--text': branding.secondaryButtons.darkText}"
    v-bind="branding.secondaryButtons">
    <v-icon class="mr-1">
      mdi-arrow-left
    </v-icon>
    {{text}}
  </v-btn>
</div>
</template>

<script>
import {
  mapActions,
} from 'redux-vuex';

import {
  executeStep,
} from '../endpoints';

export default {
  name: 'Back',
  props: {
    text: {
      type: String,
      default: () => 'Back',
    },
  },
  data() {
    return {
      ...this.mapState({
        branding: 'branding',
        backStep: 'backStep',
        stepHistory: 'stepHistory',
        workflow: 'workflow',
      }),
    };
  },
  methods: {
    ...mapActions(
      'callApi',
      'addLoad',
      'removeLoad',
      'stepBack',
    ),
    async back() {
      this.addLoad();
      await this.callApi(executeStep(
        this.workflow.url,
        this.backStep,
        this.stepHistory[(this.stepHistory.length - 2 || 0)]?.request?.call?.data,
      ));

      this.stepBack(null);
      this.removeLoad();
    },
  },
};
</script>
