<template>
<div class="">
  <!-- <h2 class="text-center mb-5">
    Login to your account
  </h2> -->
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="rounded-lg mb-7 px-2 elevation-2">
      <v-card-title class="font-weight-bold pb-0">
        Login to your Account
      </v-card-title>
      <v-card-text class="">
        <div class="mb-5">
          Please submit the Reference Code provided in the email or SMS you've received from us.
        </div>
        <div>
          <v-text-field
            class="mt-6 code-input"
            v-model="$v.form.codePart2.$model"
            required
            outlined>
          </v-text-field>
        </div>
        <div class="">
          <v-btn
            type="submit"
            block
            large
            class="font-weight-bold"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            Submit
          </v-btn>
        </div>
        <div class="mb-1 mt-3">
          If you're having trouble logging-in,
          please double check that you have the correct Reference Code from the message you received.
        </div>
      </v-card-text>
      <!-- <v-card-actions class="pb-8 pl-16 pr-6 ml-2">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          block
          large
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Login
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </form>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate';

import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'CodeInputForm',
  mixins: [validationMixin],
  computed: {
    // nameErrors() {
    //   const errors = [];
    //   if (!this.$v.form.referenceNumber.$dirty) return errors;
    //   if (this.$v.form.referenceNumber.$error) {
    //     errors.push('Please provide a name for your Strategy');
    //   }
    //   return errors;
    // },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {
        codePart1: null,
        codePart2: null,
      },
    };
  },
  validations: {
    form: {
      codePart2: {
        required,
      },
    },
  },
  methods: {
    storeValue(e, item) {
      this.$v.form[item].$model = e.toUpperCase();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      let referenceNumber = '';
      if (!this.form.codePart2.includes('-')) {
        referenceNumber = `${this.form.codePart2.substring(0, 4).toLowerCase()}-${this.form.codePart2.substring(4, 7).toLowerCase()}`;
      } else {
        referenceNumber = this.form.codePart2.toLowerCase();
      }

      this.$emit('trigger', { referenceNumber });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
<style lang="scss">
  .code-input input {
    font-family: monospace !important;
    text-align: center !important;
    letter-spacing: 8px !important;
    font-size: 18px;
  }
</style>
