<template>
<div class="mb-7">
  <v-btn
    @click="$emit('logout')"
    small
    outlined
    :class="{'black--text': branding.secondaryButtons.darkText}"
    v-bind="branding.secondaryButtons">
    <v-icon class="mr-1">
      mdi-arrow-left
    </v-icon>
    {{text}}
  </v-btn>
</div>
</template>

<script>
import {
  mapActions,
} from 'redux-vuex';

export default {
  name: 'Logout',
  props: {
    text: {
      type: String,
      default: () => 'Logout',
    },
  },
  data() {
    return {
      ...this.mapState({
        branding: 'branding',
      }),
    };
  },
  methods: {
    ...mapActions(),
  },
};
</script>
