import range from 'lodash/range';

export const LOCALE = 'en_AU';

export const monthRange = range(0, 37).map((item, index) => ({
  value: index,
  text: index === 0 ? 'Pay in full only' : `${index} month${index > 1 ? 's' : ''}`,
}));
export const dayRange = range(1, 31).map((item) => ({ value: item, text: `${item} day${item > 1 ? 's' : ''}` }));

export const contentTypeDict = {
  'text/html': {
    ContactMethodID: 3,
    text: 'Email',
  },
  'text/plain': {
    ContactMethodID: 2,
    text: 'SMS',
  },
};

export const evalProps = {
  firstName: {
    text: 'First Name',
    value: 'firstName',
  },
  lastName: {
    text: 'Last Name',
    value: 'lastName',
  },
  postCode: {
    text: 'Post Code',
    value: 'postCode',
  },
  state: {
    text: 'State',
    value: 'state',
  },
  dob: {
    text: 'Date of Birth',
    value: 'dob',
  },
  debtDate: {
    text: 'Date of Debt',
    value: 'debtDate',
  },
  gender: {
    text: 'Gender',
    value: 'gender',
  },
  email: {
    text: 'Email',
    value: 'email',
  },
  mobile: {
    text: 'Mobile Number',
    value: 'mobile',
  },
  debtAmount: {
    text: 'Debt Value',
    value: 'debtAmount',
  },
  clientReferenceNumber: {
    text: 'Account Number',
    value: 'clientReferenceNumber',
  },
  clientReferenceNumber2: {
    text: 'Secondary Value',
    value: 'clientReferenceNumber2',
  },
};

export const operatorDict = {
  '!=': {
    text: 'does not equal',
    value: '!=',
  },
  '==': {
    text: 'equals',
    value: '==',
  },
  '>': {
    text: 'is greater than',
    value: '>',
  },
  '<': {
    text: 'is less than',
    value: '<',
  },
  sw: {
    text: 'starts with',
    value: 'sw',
  },
  ew: {
    text: 'ends with',
    value: 'ew',
  },
  in: {
    text: 'includes',
    value: 'in',
  },
  fi: {
    text: 'found in',
    value: 'fi',
  },
};

export const contactMethodDict = {
  0: {
    text: 'Unknown',
    value: 0,
    icon: 'mdi-exit-run',
  },
  1: {
    text: 'Phone Call',
    value: 1,
    icon: 'mdi-phone',
  },
  2: {
    text: 'SMS',
    value: 2,
    icon: 'mdi-message-processing',
  },
  3: {
    text: 'Email',
    value: 3,
    icon: 'mdi-at',
  },
  4: {
    text: 'Mail',
    value: 34,
    icon: 'mdi-email',
  },
  5: {
    text: 'Fax',
    value: 5,
    icon: 'mdi-fax',
  },
  6: {
    text: 'Web',
    value: 6,
    icon: 'mdi-cellphone-link',
  },
  7: {
    text: 'IVR',
    value: 7,
    icon: 'mdi-phone',
  },
};

export const geographicStateList = [
  {
    StateID: 1,
    AbbreviatedState: 'ACT',
    State: 'Australian Capital Territory',
    Timezone: 'AUS Eastern Standard Time',
  },
  {
    StateID: 2,
    AbbreviatedState: 'NSW',
    State: 'New South Wales',
    Timezone: 'AUS Eastern Standard Time',
  },
  {
    StateID: 3,
    AbbreviatedState: 'NT',
    State: 'Northern Territory',
    Timezone: 'AUS Central Standard Time',
  },
  {
    StateID: 4,
    AbbreviatedState: 'SA',
    State: 'South Australia',
    Timezone: 'Cen. Australia Standard Time',
  },
  {
    StateID: 5,
    AbbreviatedState: 'TAS',
    State: 'Tasmania',
    Timezone: 'Tasmania Standard Time',
  },
  {
    StateID: 6,
    AbbreviatedState: 'QLD',
    State: 'Queensland',
    Timezone: 'E. Australia Standard Time',
  },
  {
    StateID: 7,
    AbbreviatedState: 'VIC',
    State: 'Victoria',
    Timezone: 'AUS Eastern Standard Time',
  },
  {
    StateID: 8,
    AbbreviatedState: 'WA',
    State: 'Western Australia',
    Timezone: 'W. Australia Standard Time',
  },
];

export const timeslots = [
  {
    text: '9:00 AM',
    value: '09:00',
  },
  {
    text: '9:30 AM',
    value: '09:30',
  },
  {
    text: '10:00 AM',
    value: '10:00',
  },
  {
    text: '10:30 AM',
    value: '10:30',
  },
  {
    text: '11:00 AM',
    value: '11:00',
  },
  {
    text: '11:30 AM',
    value: '11:30',
  },
  {
    text: '12:00 PM',
    value: '12:00',
  },
  {
    text: '12:30 PM',
    value: '12:30',
  },
  {
    text: '1:00 PM',
    value: '13:00',
  },
  {
    text: '1:30 PM',
    value: '13:30',
  },
  {
    text: '2:00 PM',
    value: '14:00',
  },
  {
    text: '2:30 PM',
    value: '14:30',
  },
  {
    text: '3:00 PM',
    value: '15:00',
  },
  {
    text: '3:30 PM',
    value: '15:30',
  },
  {
    text: '4:00 PM',
    value: '16:00',
  },
  {
    text: '4:30 PM',
    value: '16:30',
  },
  {
    text: '5:00 PM',
    value: '17:00',
  },
  {
    text: '5:30 PM',
    value: '17:30',
  },
  {
    text: '6:00 PM',
    value: '18:00',
  },
  {
    text: '6:30 PM',
    value: '18:30',
  },
  {
    text: '7:00 PM',
    value: '19:00',
  },
  {
    text: '7:30 PM',
    value: '19:30',
  },
  {
    text: '8:00 PM',
    value: '20:00',
  },
  {
    text: '8:30 PM',
    value: '20:30',
  },
  {
    text: '9:00 PM',
    value: '21:00',
  },
];
