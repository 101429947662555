<template>
<div class="">
  <!-- <h2 class="text-center mb-5">
    Login to your account
  </h2> -->
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="rounded-lg mb-7 px-2 elevation-2">
      <v-card-title class="font-weight-bold pb-0">
        Login to your account
      </v-card-title>
      <v-card-text class="">
        <div class="mb-5">
          Please submit the reference code provided in the email or SMS you've received from us.
        </div>
        <div class="d-flex">
          <v-otp-input
            name="code-input-2"
            tabindex="0"
            class="text-uppercase"
            length="7"
            :value="$v.form.codePart2.$model"
            autocomplete="false"
            @input="storeValue($event, 'codePart2')"
            @finish="submit">
          </v-otp-input>
        </div>
        <div class="mb-1 mt-3">
          If you're having trouble logging-in,
          please check that you've submitted the correct series of letters and numbers from your reference code.
        </div>
      </v-card-text>
      <!-- <v-card-actions class="pb-8 pl-16 pr-6 ml-2">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          block
          large
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Login
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </form>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate';

import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'ReferenceNumberForm',
  mixins: [validationMixin],
  computed: {
    // nameErrors() {
    //   const errors = [];
    //   if (!this.$v.form.referenceNumber.$dirty) return errors;
    //   if (this.$v.form.referenceNumber.$error) {
    //     errors.push('Please provide a name for your Strategy');
    //   }
    //   return errors;
    // },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {
        codePart1: null,
        codePart2: null,
      },
    };
  },
  validations: {
    form: {
      codePart2: {
        required,
      },
    },
  },
  methods: {
    storeValue(e, item) {
      this.$v.form[item].$model = e.toUpperCase();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      const code = `${
        this.form.codePart2.substring(0, 4).toLowerCase()
      }-${
        this.form.codePart2.substring(4, this.form.codePart2.length).toLowerCase()
      }`;

      this.$emit('trigger', {
        value: code,
        form: code,
        referenceNumber: code,
      });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
