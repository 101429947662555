import envConfig from 'brand/env';

import {
  findEnv,
} from './utils';

import {
  startWorkflow,
  whoAmI,
  executeStep,
  deleteStep,
} from './endpoints';

const actions = {
  setMetaTags: (payload) => ({
    type: 'SET_META_TAGS',
    payload,
  }),
  setWidgetFields: (payload) => ({
    type: 'SET_WIDGET_FIELDS',
    payload,
  }),
  setPageValidation: (payload) => ({
    type: 'SET_PAGE_VALIDATION',
    payload,
  }),
  setLocalContext: (payload) => ({
    type: 'SET_LOCAL_CONTEXT',
    payload,
  }),
  setCurrentKey: (payload) => ({
    type: 'SET_CURRENT_KEY',
    payload,
  }),
  setLoadingReasons: (payload) => ({
    type: 'SET_LOADING_REASONS',
    payload,
  }),
  setFooter: (payload) => ({
    type: 'SET_FOOTER',
    payload,
  }),
  setContext: (payload) => ({
    type: 'SET_CONTEXT',
    payload,
  }),
  setTheme: (payload) => ({
    type: 'SET_THEME',
    payload,
  }),
  setBranding: (payload) => ({
    type: 'SET_BRANDING',
    payload,
  }),
  setWorkflow: (payload) => ({
    type: 'SET_WORKFLOW',
    payload,
  }),
  setSession: (payload) => ({
    type: 'SET_SESSION',
    payload,
  }),
  setWidget: (payload) => ({
    type: 'SET_WIDGET',
    payload,
  }),
  stepNext: (payload) => ({
    type: 'NEXT_STEP',
    payload,
  }),
  stepBack: (payload) => ({
    type: 'BACK_STEP',
    payload,
  }),
  stepOptions: (payload) => ({
    type: 'STEP_OPTIONS',
    payload,
  }),
  addStep: (payload) => ({
    type: 'ADD_STEP',
    payload,
  }),
  addLoad: (payload) => ({
    type: 'ADD_LOAD',
    payload,
  }),
  removeLoad: (payload) => ({
    type: 'REMOVE_LOAD',
    payload,
  }),
  callStart: (payload) => ({
    type: 'CALL_START',
    payload,
  }),
  callEnd: (payload) => ({
    type: 'CALL_END',
    payload,
  }),
  setRedirectStep: (payload) => ({
    type: 'SET_REDIRECT_STEP',
    payload,
  }),
};

const callApi = (call) => (dispatch) => new Promise((resolve, reject) => {
  const {
    customHeaders = {},
    noContentType = false,
    noHeaders = null,
    rawBody = false,
    customEndpoint = null,
    loc = 'wfApi',
  } = call;

  const headers = noHeaders ? new Headers({}) : new Headers({
    ...(!noContentType && { 'Content-Type': 'application/json' }),
    ...customHeaders,
  });

  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  let cRes = {};
  const resolvedEndpoint = customEndpoint || `${call.url || env[loc]}${call.endpoint}`;
  dispatch(actions.callStart(call));

  fetch(resolvedEndpoint, {
    method: call.method || 'GET',
    headers,
    body: rawBody ? call.data : JSON.stringify(call.data),
  })
    .then((res) => {
      cRes = res;
      return rawBody ? cRes : cRes.json();
    })
    .then((data) => ({
      meta: cRes,
      data: call.apiResource && !rawBody ? data[call.apiResource] : data,
    }))
    .then((res) => {
      dispatch(actions.callEnd({ ...res, call }));

      if (!res.meta.ok) {
        console.log('throw!!');
        throw res;
      }

      return resolve(res);
    })
    .catch((e) => {
      reject(e);
    });
});

const dispatchActions = (newState) => (dispatch) => {
  Object.keys(newState).forEach((k) => {
    if (!actions[k]) return;
    dispatch(actions[k](newState[k]));
  });
};

const signOut = () => async (dispatch, getState) => {
  dispatch(actions.setContext([
    [
      {
        classes: 'col-md-5',
        elements: [
          {
            component: 'List',
            props: {
              title: "You've been Logged Out",
              subtitle: 'Refresh this page to restart.',
              items: [],
            },
          },
        ],
      },
    ],
  ]));

  dispatch(actions.setSession(null));

  localStorage.removeItem('yabbr-wizard-store');
  try {
    await Promise.all(getState().stepHistory.map((s) => dispatch(callApi(deleteStep(getState().workflow.url, s.step)))));
    if (getState().nextStep) {
      await dispatch(callApi(deleteStep(getState().workflow.url, getState().nextStep)));
    }
  } catch (e) {
    console.log(e);
  }
};

const appInit = () => async (dispatch, getState) => {
  // we're not in a workflow. start a new one!
  try {
    dispatch(actions.addLoad());
    await dispatch(callApi(whoAmI(window.location.host + (window.location.pathname.length > 1 ? window.location.pathname : ''))));

    if (getState().stepHistory.length && getState().stepHistory.length > 1) {
      console.log('Recalling from last executed step');
      await dispatch(callApi(executeStep(
        getState().workflow.url,
        getState().stepHistory[(getState().stepHistory.length - 1 || 0)].step,
        {},
        { ignore: true },
      )));
      return;
    }

    const splitQueries = (url) => {
      const parts = url.split('?');
      if (parts.length === 1) return {};

      return parts[1].split('&').reduce((acc, cv) => {
        const pair = cv.split('=');
        acc[pair[0]] = pair[1];

        return acc;
      }, {});
    };

    const decodeObject = (object) => Object.entries(object).reduce((acc, cur) => ({
      ...acc,
      [decodeURIComponent(cur[0])]: decodeURIComponent(cur[1]),
    }), {});

    const queryParams = decodeObject(splitQueries(window.location.href));

    await dispatch(callApi(startWorkflow(
      getState().workflow.url,
      getState().workflow.id,
      queryParams,
    )));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(actions.removeLoad());
  }
};

const fireKey = (key, stepId, body, mergeBody = {}) => async (dispatch, getState) => {
  dispatch(actions.setCurrentKey(key));
  dispatch(actions.addLoad());

  let result = null;
  try {
    let payload = { ...mergeBody, ...body };
    if (getState().stepOptionList.history) {
      payload = { ...payload, history: getState().stepOptionList.history };
    }
    result = await dispatch(callApi(executeStep(getState().workflow.url, stepId, payload)));
  } catch (err) {
    console.log(err);
  } finally {
    console.log(result);
    dispatch(actions.removeLoad());
  }
};

export default {
  appInit,
  callApi,
  fireKey,
  dispatchActions,
  signOut,
  ...actions,
};
