<template>
  <div>
    <v-card
      v-for="(item, index) in items" :key="index"
      class="rounded-lg mb-6"
      :elevation="elevation"
      :outlined="outlined"
      :dark="darkMode"
      :style="`background-color: ${bgColour};`">
      <v-card-title class="font-weight-bold pb-0 text-h7" :class="{'px-5': !item.icon}" v-if="item.title">
        <v-icon :color="color" v-if="icon" class="mr-4">{{item.con}}</v-icon>
        {{item.title}}
      </v-card-title>
      <v-card-text :class="{'px-5': !icon, 'pl-14 pr-7': item.icon}"  v-if="item.subtitle">
        {{getContent(item.subtitle)}}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment';
import get from 'lodash/get';

export default {
  name: 'DisplayCard',
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    prompt: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: () => '',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    darkMode: {
      type: Boolean,
      default: () => false,
    },
    elevation: {
      type: Number,
      default: () => 2,
    },
    outlined: {
      type: Boolean,
      default: () => false,
    },
    divided: {
      type: Boolean,
      default: () => true,
    },
    minLength: {
      type: Number,
      default: () => 0,
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    bgColour: {
      type: String,
      default: () => 'white',
    },
  },
  computed: {
    paddingElements() {
      return [];
    },
  },
  methods: {
    moment,
    getContent(content) {
      if (!Array.isArray(content)) {
        return content;
      }

      return content.join(' ');
    },
    itemClickable(item) {
      return !!item.action || !!item.option || !!item.href || !!item.key;
    },
    handleClick(item) {
      console.log(item);
      if (item.action === 'trigger-widget-open') {
        window.openYabbrWidget();
        return false;
      }

      if (item.action) {
        this.$emit(item.action.type);
        return null;
      }

      if (item.option) {
        const opt = {
          key: item.option,
          body: {
            option: item.option,
            instanceKey: this.instanceKey,
            form: item.option,
          },
        };

        if (item.localState) {
          Object.keys(item.localState).forEach((ls) => {
            opt.body[ls] = get(this, item.localState[ls]);
          });
        }
        this.$emit('option', opt);
        return null;
      }

      if (item.key) {
        console.log(item.key);
        const opt = {
          key: item.key,
          body: {},
        };

        this.$emit('key-fire', opt);
        return null;
      }

      return null;
    },
    getHref(item) {
      if (!item.href) return false;
      return `${item.href}`;
    },
  },
  data() {
    return {
      ...this.mapState('localContext'),
    };
  },
};
</script>
