<template>
<div class="mb-5">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2">
      <v-card-text class="px-8">
        <v-text-field
          class="mt-6"
          type="text"
          v-model="inputModel"
          :label="inputLabel"
          required
          outlined
          dense
          @click:append-outer="addResponse(inputModel)"
          append-outer-icon="mdi-plus-circle">
        </v-text-field>
        <v-list v-if="items.length" dense>
          <v-list-item :key="rInd" v-for="(res, rInd) in items" style="min-height: 28px;">
            <v-list-item-content class="py-0">
              <v-list-item-title class="d-flex">
                {{ res }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
              <v-btn icon @click="removeResponse(rInd)">
                <v-icon color="grey">mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="px-8 pb-6">
        <v-spacer> </v-spacer>
        <v-btn
          type="submit"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          :disabled="this.items.length === 0"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="22" class="ml-2 mt-1">
              mdi-arrow-right-circle
            </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate';

import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'InputListSimple',
  mixins: [validationMixin],
  props: {
    error: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => 'Enter Code',
    },
    description: {
      type: String,
      default: () => 'Please enter the code provided',
    },
    icon: {
      type: String,
      default: () => 'mdi-check-circle',
    },
    inputType: {
      type: String,
      default: () => 'text',
    },
    inputLabel: {
      type: String,
      default: () => 'Enter Code',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      if (this.$v.form.value.$error) {
        errors.push('Please provide a value');
      }
      return errors;
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {
        value: null,
      },
      inputModel: '',
      items: [],
    };
  },
  validations: {
    form: {
      value: {
        required,
      },
    },
  },
  methods: {
    addResponse() {
      this.items.push(this.inputModel);
      this.inputModel = '';
    },
    removeResponse(index) {
      this.items.splice(index, 1);
    },
    submit() {
      this.$emit('trigger', { form: this.items, instanceKey: this.instanceKey });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
