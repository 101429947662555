<template>
  <h1 class="mt-2 mb-5">
    {{text}}
  </h1>
</template>

<script>

export default {
  name: 'Intro',
  props: {
    text: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState(),
    };
  },
};
</script>
