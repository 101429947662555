<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2">
      <v-card-text class="px-5 pt-5">
        <v-text-field
          :label="label"
          v-model="searchInput">
        </v-text-field>
        <div class="selected-window" style="max-height: 300px;" v-if="selectedList.length">
          <v-list>
            <v-list-item-group :value="selectedList.map((s, i) => i)" color="primary" multiple>
              <v-list-item
                class="mt-0 pl-2"
                v-for="(item, index) in selectedList"
                :key="index"
                @click="removeItem(index)">
                <v-list-item-avatar class="mr-2">
                  <v-icon>
                    mdi-check-circle
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{item}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div class="search-window" style="max-height: 300px; overflow-y: scroll;">
          <v-list>
            <v-list-item class="mt-0 pl-2"
              v-for="(item, index) in displayList"
              :key="index"
              @click="addItem(item)">
              <v-list-item-avatar class="mr-2">
                <v-icon>
                  mdi-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{item}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions class="px-12 pb-8">
        <v-btn
          type="submit"
          block
          large
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>

export default {
  name: 'InputMultiselectList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    displayProp: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: () => 'Filter',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    reason: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {},
      selectedList: [],
      searchInput: '',
    };
  },
  computed: {
    displayList() {
      let listItems = this.items;
      if (this.displayProp) {
        listItems = listItems.map((i) => i[this.displayProp]);
      }

      console.log(listItems);

      return listItems.filter((i) => this.selectedList.indexOf(i) === -1
        && i.toLowerCase().includes(this.searchInput.trim().toLowerCase()));
    },
  },
  methods: {
    addItem(item) {
      this.selectedList.push(item);
    },
    removeItem(index) {
      this.selectedList.splice(index, 1);
    },
    submit() {
      this.$emit('trigger', {
        form: {
          listCompatible: this.selectedList.map((val) => ({ title: val })),
          values: this.selectedList,
          asString: this.selectedList.join('</br></br>'),
        },
        instanceKey: this.instanceKey,
        reason: this.reason,
      });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
