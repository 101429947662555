<template>
  <div class="d-flex flex-column px-15 mt-10">
    <v-btn
      class="mb-7"
      color="primary"
      block
      large
      raised>
      Confirm
    </v-btn>
    <v-btn
      color="primary"
      text
      block
      large
      raised>
      Logout
    </v-btn>
  </div>

</template>

<script>

export default {
  name: 'ConfirmIdentity',
  props: {
    referenceNumber: {
      type: String,
      default: () => '',
    },
    modalTitle: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState(),
    };
  },
};
</script>
