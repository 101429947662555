<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2 mb-5">
      <v-card-text class="px-8 pt-5">
        <v-select
          :label="item.text"
          :key="index"
          v-for="(item, index) in items"
          @change="onChange($event, item)"
          :value="form[item.key]"
          item-text="text"
          item-value="value"
          :items="getOptions(item.options, item.optionFilter)">
        </v-select>
      </v-card-text>
      <v-card-actions class="px-8 pb-6">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :disabled="isDisabled"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="22" class="ml-2 mt-1">
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>

export default {
  name: 'InputMultiselect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {},
    };
  },
  computed: {
    isDisabled() {
      return Object.keys(this.form).length === 0;
    },
  },
  methods: {
    getOptions(options, filter) {
      const precompiled = options.map((o, i) => ({
        text: o,
        value: i,
      }));

      console.log(precompiled);
      if (!filter || !filter.length) return precompiled;

      const fil = filter.map((f) => precompiled.find((e) => e.value === Number(f)));

      console.log(fil);

      return fil;
    },
    onChange(e, f) {
      console.log(e);
      this.$set(this.form, f.key, {
        text: f.key,
        value: e,
      });
    },
    submit() {
      this.$emit('trigger', { form: this.form, instanceKey: this.instanceKey });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
