<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2 mb-6 pt-4">
      <v-card-text class="px-12">
        <v-file-input
          :disabled="isWorking"
          v-model="$v.form.value.$model"
          show-size
          counter
          truncate-length="15"
        ></v-file-input>
      </v-card-text>
      <v-card-actions class="px-12 pb-10">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :disabled="!$v.form.value.$model"
          :loading="isWorking"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
            <v-icon size="22" class="ml-2 mt-1">
                mdi-arrow-right-circle
            </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>
import {
  mapActions,
} from 'redux-vuex';

import { validationMixin } from 'vuelidate';

import {
  required,
} from 'vuelidate/lib/validators';

import {
  postFile,
} from '../endpoints';

export default {
  name: 'InputFile',
  mixins: [validationMixin],
  props: {
    destination: {
      type: String,
      default: () => '',
    },
    fields: {
      type: Object,
      default: () => {},
    },
    trigger: {
      type: String,
      default: () => '',
    },
    reason: {
      type: String,
      default: () => '',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      if (this.$v.form.value.$error) {
        errors.push('Please provide a value');
      }
      return errors;
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      isWorking: false,
      form: {
        value: null,
      },
    };
  },
  validations: {
    form: {
      value: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(
      'callApi',
    ),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      const formData = new FormData();

      Object.keys(this.fields).forEach((k) => {
        formData.append(k, this.fields[k]);
      });

      formData.append('file', this.$v.form.value.$model);
      this.isWorking = true;
      await this.callApi(postFile(
        this.destination,
        formData,
      ));

      this.isWorking = false;
      this.$emit('next', {
        key: this.trigger,
        reason: this.reason,
        friendly: this.$v.form.value.$model.name,
        instanceKey: this.instanceKey,
        form: { name: this.$v.form.value.$model.name },
      });
    },
  },
};
</script>
