<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2 mb-4 mt-2">
      <v-card-title class="font-weight-bold pb-0" v-if="title">
        <v-icon :color="error ? 'error' : 'primary'" class="mr-2">{{icon}}</v-icon>
        {{title}}
      </v-card-title>
      <v-card-text class="px-5">
        <div class="" v-if="description">
          {{description}}
        </div>
        <v-text-field
          v-if="inputType !== 'textarea'"
          class="mt-3"
          :type="inputType"
          v-model="$v.form.value.$model"
          :label="inputLabel"
          required
          outlined
          :hint="hint"
          dense
          :error-messages="valueErrors">
        </v-text-field>
        <v-textarea
          v-if="inputType === 'textarea'"
          class="mt-3"
          :type="inputType"
          v-model="$v.form.value.$model"
          :label="inputLabel"
          required
          :rules="rules"
          outlined
          :hint="hint"
          counter
          dense>
        </v-textarea>
      </v-card-text>
      <v-card-actions class="px-5 pb-10">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="22" class="ml-2 mt-1">
              mdi-arrow-right-circle
            </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { smsCount } from '../utils';

export default {
  name: 'InputForm',
  mixins: [validationMixin],
  props: {
    error: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => 'mdi-check-circle',
    },
    inputType: {
      type: String,
      default: () => 'text',
    },
    inputLabel: {
      type: String,
      default: () => 'Enter Code',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    hint: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    formattedCount() {
      const val = smsCount(this.$v.form.value.$model);
      return `${val.units} unit${val.units < 1
          || val.units > 1 ? 's' : ''}, ${this.$v.form.value.$model ? this.$v.form.value.$model.length : 0} characters`;
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      if (this.$v.form.value.$error) {
        errors.push('Please provide a value');
      }
      return errors;
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {
        value: null,
      },
      rules: [(v) => (v && v.length <= 160) || 'Warning: Your message exceeds 1 SMS unit'],
    };
  },
  validations: {
    form: {
      value: {
        required,
      },
    },
  },
  methods: {
    smsCount,
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      this.$emit('trigger', {
        value: this.form.value,
        form: this.form.value,
        instanceKey: this.instanceKey,
      });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
