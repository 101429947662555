import {
  createStore,
  applyMiddleware,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import callTriggerMiddleware from './middleware/callTriggerMiddleware';
import saveStoreMiddleware from './middleware/saveStoreMiddleware';

function configureStore() {
  let preloadedState = {};
  if (localStorage.getItem('yabbr-wizard-store')) {
    try {
      const store = JSON.parse(localStorage.getItem('yabbr-wizard-store'));
      preloadedState = {
        branding: store.branding,
        stepHistory: store.stepHistory,
        workflow: store.workflow,
        theme: store.theme,
      };
    } catch (e) {
      console.log(e);
    }
  }

  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        callTriggerMiddleware,
        saveStoreMiddleware,
      ),
    ),

  );
}

export default configureStore();
