<template>
  <div class="">
    <v-container class="mt-3" v-if="isWorking">
      <v-row justify="center">
        <v-col md="6" col="12">
          <v-skeleton-loader
            type="table-heading, list-item-two-line">
          </v-skeleton-loader>
          <v-skeleton-loader
            class="mt-4"
            type="list-item-three-line">
          </v-skeleton-loader>
          <v-skeleton-loader
            class="mt-4"
            type="article, actions">
          </v-skeleton-loader>
          <v-skeleton-loader
            class="mt-4"
            type="list-item-three-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <form
      v-if="!isWorking"
      novalidate
      @submit.prevent="submit">
      <v-card class="rounded-lg mb-sm-10 mb-4 mt-3 elevation-2">
        <div class="text-center pt-6 text-h3" color="primary">
          ${{debtValue}}
        </div>
        <div class="mx-12 pt-3">
          <v-card-text class="text-center mb-1 pb-0 font-weight-bold">
            When would you like to pay?
          </v-card-text>
          <div class="d-flex justify-center flex-wrap">
            <v-btn
              v-for="(item, index) in availableStartDates"
              :key="index"
              class="ma-1"
              v-bind="branding.secondaryButtons"
              :outlined="$v.form.commencementDate.$model !== item.format('YYYY-MM-DD')"
              @click="$v.form.commencementDate.$model = item.format('YYYY-MM-DD');">
              {{item.format('ddd, MMM DD')}}
            </v-btn>
          </div>
        </div>
        <div class="mt-1" v-if="paymentMethodsLocal.length > 1">
          <v-card-text class="text-center mb-0 pb-0 font-weight-bold">
            How would you like to pay?
          </v-card-text>
        </div>
        <div class="mx-12 mt-2 mb-6 d-flex justify-center">
          <div class="d-flex justify-center">
            <v-btn-toggle
              v-if="paymentMethodsLocal.length > 1"
              mandatory
              v-model="$v.form.paymentMethod.$model"
              :class="{'black--text': branding.secondaryButtons.darkText}"
              v-bind="branding.secondaryButtons"
              borderless>
              <v-btn
                :value="item.value"
                class="px-6"
                :key="index"
                v-for="(item, index) in paymentMethodsLocal">
                <div class="d-flex">
                  <div class="mr-1">
                    <v-icon dense>
                      {{item.icon}}
                    </v-icon>
                  </div>
                  <div class="font-weight-bold align-self-center">
                    {{item.name}}
                  </div>
                </div>
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <v-card-actions class="pl-6 pr-6 pb-6 pt-2">
          <v-btn
            type="submit"
            block
            x-large
            class="font-weight-bold"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <span v-if="cta === 'proceed'">Proceed to Pay</span>
            <span v-if="cta === 'summary'">
              Pay ${{debtValue}}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';

import { nextProcessingDay } from '../utils';

export default {
  name: 'PaymentFullForm',
  mixins: [validationMixin],
  props: {
    daysToFirstPayment: {
      type: Number,
      default: () => 1,
    },
    debtValue: {
      type: Number,
      default: () => 1,
    },
    commencementDate: {
      type: Date,
      default: () => nextProcessingDay(new Date()),
    },
    paymentMethods: {
      type: Array,
      default: () => [2, 3],
    },
    cta: {
      type: String,
      default: () => 'summary',
    },
    maxPADate: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      ...this.mapState('branding', 'stepOptionList'),
      form: {
        paymentPlanAmount: null,
        frequencyCode: 1,
        commencementDate: null,
        paymentMethod: 2,
        paymentType: 2,
      },
      localDaysToFirstPayment: 1,
      localDebtValue: 1,
      isWorking: false,
    };
  },
  validations: {
    form: {
      paymentPlanAmount: {
        required,
      },
      commencementDate: {
        required,
      },
      paymentMethod: {
        required,
      },
    },
  },
  computed: {
    localCapDate() {
      const maLengthDate = moment().add(this.localDaysToFirstPayment, 'days');
      if (this.maxPADate && moment(this.maxPADate).isBefore(maLengthDate)) return moment(this.maxPADate);
      return maLengthDate;
    },
    maxArrangementLengthInDays() {
      return this.localCapDate.diff(moment(this.commencementDate), 'days');
    },
    availableStartDates() {
      const times = [this.maxArrangementLengthInDays, this.localDaysToFirstPayment].sort((a, b) => a - b);
      const dateArray = [];

      for (let i = 0; i < times[0]; i += 1) {
        const d = moment(this.commencementDate).add(i, 'days');

        if (['Mo', 'Tu', 'We', 'Th', 'Fr'].indexOf(d.format('dd')) !== -1) {
          dateArray.push(d);
        }
      }

      return dateArray;
    },
    paymentMethodsLocal() {
      return [
        {
          value: 2,
          name: 'Debit Card',
          icon: 'mdi-credit-card-outline',
        },
        {
          value: 3,
          name: 'Direct Debit',
          icon: 'mdi-bank',
        },
      ].filter((r) => this.paymentMethods.indexOf(r.value) !== -1);
    },
  },
  methods: {
    moment,
    allowedDates(val) {
      const m = moment(val);
      return m.isBefore(moment().add(this.localDaysToFirstPayment, 'days'))
        && m.isAfter(moment())
        && ['Sa', 'Su'].indexOf(m.format('dd')) === -1;
    },
    changeInterval(e) {
      this.$v.form.frequencyCode.$model = e;
    },
    submit() {
      this.form.paymentPlanAmount = this.debtValue;
      this.form.returnURLSuccess = `${window.location.href}?s=${this.stepOptionList.success}`;
      this.form.returnURLFailure = `${window.location.href}?s=${this.stepOptionList.failure}`;
      this.form.returnURLToWebsite = `${window.location.href}?s=${this.stepOptionList.return}`;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      this.isWorking = true;
      this.$emit('trigger', this.form);
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
  mounted() {
    this.localDaysToFirstPayment = Number(this.daysToFirstPayment);
    this.form.commencementDate = moment(this.commencementDate).format('yyyy-MM-DD');
  },
};
</script>
