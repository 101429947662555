<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2 mb-5 mt-3">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Property
              </th>
              <th class="text-left">
                Condition
              </th>
              <th class="text-left">
                Comparison
              </th>
              <th class="text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in $v.form.conditions.$model"
              :key="index">
              <td class="text-truncate" style="max-width: 50px;">
                {{ evalProps[getAttribute(item.value1)] ? evalProps[getAttribute(item.value1)].text : '!Undefined' }}
              </td>
              <td>{{ operatorDict[item.operator].text }}</td>
              <td class="text-truncate" style="max-width: 50px;">{{ item.value2 }}</td>
              <td>
                <v-icon dense @click="removeItem(index)" class="cursor-pointer">
                  mdi-close-circle
                </v-icon>
              </td>
            </tr>
            <tr>
              <td>
                <v-select
                  style="width: 170px;"
                  name="value1"
                  class="mt-5 mr-2"
                  id="value1"
                  :items="evalPropsArray"
                  item-text="text"
                  item-value="value"
                  required
                  outlined
                  dense
                  :error-messages="conditionErrors"
                  v-model="inputVals.value1">
                </v-select>
              </td>
                <v-select
                  style="width: 170px;"
                  name="eval"
                  class="mt-5 mr-2"
                  id="eval"
                  item-text="text"
                  item-value="value"
                  :items="operatorDictArray"
                  required
                  outlined
                  dense
                  :error-messages="conditionErrors"
                  v-model="inputVals.operator">
                </v-select>
              <td>
                <v-text-field
                  v-if="['tr', '!tr'].indexOf(inputVals.operator) === -1"
                  autocomplete="off"
                  name="value2"
                  class="mt-5"
                  outlined
                  dense
                  :error-messages="conditionErrors"
                  v-model="inputVals.value2">
                </v-text-field>
              </td>
              <td>
                <v-btn
                  @click="addCondition"
                  small
                  icon
                  text>
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-actions class="px-8 pb-6">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :disabled="isDisabled"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="22" class="ml-2 mt-1">
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'InputConditions',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      ...this.mapState('branding'),
      form: {
        conditions: [],
        ledger: null,
        name: null,
        priority: null,
      },
      tab: '1',
      inputVals: {
        value1: null,
        operator: null,
        value2: null,
      },
      evalProps: {
        Human: {
          text: 'Human',
          value: 'Human',
        },
        Machine: {
          text: 'Machine',
          value: 'Machine',
        },
        Delivered: {
          text: 'Delivered',
          value: 'Delivered',
        },
        'Opted Out': {
          text: 'Opted Out',
          value: 'Opted Out',
        },
        Processed: {
          text: 'Processed',
          value: 'Processed',
        },
        Question1: {
          text: 'Question 1',
          value: 'Q1: Result',
        },
        Question2: {
          text: 'Question 2',
          value: 'Q2: Result',
        },
        Question3: {
          text: 'Question 3',
          value: 'Q3: Result',
        },
        Question4: {
          text: 'Question 4',
          value: 'Q4: Result',
        },
        Question5: {
          text: 'Question 5',
          value: 'Q5: Result',
        },
        Question6: {
          text: 'Question 6',
          value: 'Q6: Result',
        },
        Question7: {
          text: 'Question 7',
          value: 'Q7: Result',
        },
        Question8: {
          text: 'Question 8',
          value: 'Q8: Result',
        },
      },
      operatorDict: {
        '!=': {
          text: 'does not equal',
          value: '!=',
        },
        '==': {
          text: 'equals',
          value: '==',
        },
        '>': {
          text: 'is greater than',
          value: '>',
        },
        '<': {
          text: 'is less than',
          value: '<',
        },
        sw: {
          text: 'starts with',
          value: 'sw',
        },
        ew: {
          text: 'ends with',
          value: 'ew',
        },
        in: {
          text: 'includes',
          value: 'in',
        },
        fi: {
          text: 'found in',
          value: 'fi',
        },
        '!fi': {
          text: 'not found in',
          value: '!fi',
        },
        tr: {
          text: 'Exists',
          value: 'tr',
        },
        '!tr': {
          text: 'Does not exist',
          value: '!tr',
        },
      },
      wfPayloads: {
        import: 'memory.record.',
      },
    };
  },
  computed: {
    conditionErrors() {
      const errors = [];
      if (!this.$v.form.conditions.$dirty) return errors;
      if (this.$v.form.conditions.$error) {
        errors.push('You need at least one Condition on a Rule');
      }
      return errors;
    },
    isDisabled() {
      return Object.keys(this.form).length === 0;
    },
    operatorDictArray() {
      return Object.values(this.operatorDict);
    },
    evalPropsArray() {
      return Object.values(this.evalProps);
    },
  },
  validations: {
    form: {
      conditions: {
        required,
      },
    },
  },
  methods: {
    getAttribute(val) {
      const temp = val.split(this.wfPayloads.import);
      if (temp.length === 1) return temp;

      const attr = temp[1];
      return attr.slice(0, attr.length - 2);
    },
    setAttr(val) {
      return `{{${this.wfPayloads.import}${val}}}`;
    },
    remove() {
      this.$emit('remove', this.form);
      this.$emit('close-dialog');
    },
    removeItem(index) {
      this.$v.form.conditions.$model.splice(index, 1);
    },
    clearInputVals() {
      this.inputVals = {
        value1: null,
        value2: null,
        operator: null,
      };
    },
    addCondition() {
      this.$v.form.conditions.$model.push({
        ...cloneDeep(this.inputVals),
        value1: this.setAttr(this.inputVals.value1),
      });
      this.clearInputVals();
    },
    // submit() {
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     this.$emit('error', 'invalid');
    //     return;
    //   }

    //   console.log(this.form);

    //   this.$emit('form-details', this.form);
    //   this.$emit('error', null);
    //   this.$emit('close-dialog');
    // },
    submit() {
      this.$emit('trigger', { form: this.form, instanceKey: this.instanceKey });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
