<template>
  <div>
    <!-- <div class="mb-2 ml-3 text-body-2">
      {{prompt}}
    </div> -->
    <v-card class="rounded-lg mb-sm-10 mb-4 elevation-2">
      <div class="">
        <v-card-title class="font-weight-bold pb-0" :class="{'px-5': !icon}">
          <v-icon color="primary" v-if="icon" class="mr-4">{{icon}}</v-icon>
          {{title}}
        </v-card-title>
        <div class="pt-3" v-if="isComplete">

        </div>
        <v-card-text v-if="!isComplete" :class="{'px-5': !icon, 'pl-14 pr-7': icon}">
          Your account will be settled in <span class="font-weight-bold">{{timeToLast}}</span>
        </v-card-text>
      </div>

      <div class="mx-4 mb-2" v-if="paymentProgress">
        <v-progress-linear :value="paymentProgress" color="primary" height="10" rounded>
        </v-progress-linear>
      </div>
      <v-divider v-if="!paymentProgress"></v-divider>
      <v-list max-height="300" style="overflow-y: auto;">
        <div class="" v-for="(item, index) in items" :key="index">
          <v-list-item class="mt-0 pl-2" two-line :href="getHref(item)" color="primary">
            <v-list-item-avatar class="mr-2">
              <v-icon :color="item.ScheduledPaymentStatusID !== 4 ? 'grey' : 'primary'">
                {{item.ScheduledPaymentStatusID !== 4 ? 'mdi-alarm' : 'mdi-check-circle-outline'}}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ moment(item.Date).format('ddd, Do MMM YYYY') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <span v-if="!item.MatchedTransactionID || item.ScheduledPaymentStatusID !== 4">
                  Payment Pending
                </span>
                <span v-if="item.MatchedTransactionID && item.ScheduledPaymentStatusID == 4">
                  Complete - Ref {{item.MatchedTransactionID}}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              ${{item.Amount}}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PaymentList',
  props: {
    isComplete: {
      type: Boolean,
      default: () => true,
    },
    icon: {
      type: String,
      default: () => '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    originalValue: {
      type: Number,
      default: () => 1,
    },
    totalPayments: {
      type: Number,
      default: () => 1,
    },
    title: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    timeToLast() {
      return moment.duration(moment().diff(this.items[this.items.length - 1].Date)).humanize();
    },
    paymentProgress() {
      if (!this.totalPayments || !this.originalValue) return 0;
      return Math.floor((this.totalPayments / this.originalValue) * 100);
    },
  },
  methods: {
    moment,
    getHref(item) {
      if (!item.href) return false;
      return `${item.href}${item.title}`;
    },
  },
  data() {
    return {
      ...this.mapState(),
    };
  },
};
</script>
