<template>
  <v-app>
        <v-app-bar
          v-if="!globalWorkCount"
          absolute
          app
          class="elevation-2"
          color="white">
          <v-container class="pa-0 fill-height py-sm-1">
            <v-img v-if="branding.logoURL" :src="branding.logoURL" max-height="40" max-width="200" contain>
            </v-img>
            <h4 class="ml-6 font-weight-bold d-none d-md-flex">{{branding.title}}</h4>
            <v-spacer></v-spacer>
            <div class="" v-if="session">
              <v-list-item
                  class="px-2 pr-4 font-weight-semibold"
                  @click="signOut">
                <v-list-item-avatar class="mr-0 ml-0 my-0">
                  <v-icon color="primary">
                    mdi-logout
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="d-none d-md-flex">
                  <v-list-item-title>
                    {{session.name || 'Restart'}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-container>
        </v-app-bar>
    <v-main class="mx-4 mx-s-0 mt-sm-4 mt-0" style="min-height: 600px;">
      <v-container class="mt-12" v-if="globalWorkCount">
        <v-row justify="center">
          <v-col md="6" col="12">
            <v-skeleton-loader
              type="table-heading, list-item-two-line">
            </v-skeleton-loader>
            <v-skeleton-loader
              class="mt-4"
              type="list-item-three-line">
            </v-skeleton-loader>
            <v-card class="rounded-l mt-4" elevation="0">
              <v-card-text class="text-center">
                {{loadingReasons[currentKey] || 'Loading'}}...
              </v-card-text>
            </v-card>
            <v-skeleton-loader
              class="mt-4"
              type="article, actions">
            </v-skeleton-loader>
            <v-skeleton-loader
              class="mt-4"
              type="list-item-three-line">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mt-4" v-if="!globalWorkCount">
        <v-overlay absolute v-if="!localValidation" :dark="false">
          <v-card class="px-8" width="340">
            <div class="d-flex justify-center pt-4">
              <v-icon size="100">
                mdi-account-lock
              </v-icon>
            </div>
            <v-card-title class="text-center d-flex justify-center">
              Verify your Identity
            </v-card-title>
            <v-card-subtitle class="text-center">
              Please enter your Date of Birth to view this document.
            </v-card-subtitle>
            <v-text-field
              class="px-2 mt-4"
              type="date"
              v-model="validityField"
              label="Date of Birth"
              required
              outlined
              dense>
            </v-text-field>
            <v-card-actions>
              <v-btn
                @click="checkValidity"
                class="mb-8"
                block
                color="primary">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-overlay>
        <!-- <v-row justify="center">
          <v-col class="col-md-8">
            <v-btn color="primary" text>
              <v-icon class="mr-1">
                mdi-arrow-left-thick
              </v-icon>
              Back to Overview
            </v-btn>
          </v-col>
        </v-row> -->
        <v-row justify="center" v-for="(r, ri) in context" :key="ri">
          <v-col v-for="(c, ci) in r" :key="ci" :class="c.classes">
            <component
              v-for="(cont, index) in c.elements"
              :key="index"
              :is="cont.component"
              @option="fireKey($event.key, stepOptionList[$event.key], $event.body, storedBody)"
              @key-fire="fireKey($event.key, $event.key, $event.body, storedBody)"
              @next="fireKey('next', nextStep, $event, storedBody)"
              @logout="signOut"
              @trigger="fireKey('next', nextStep, $event, storedBody)"
              @store-local-body="storeLocalBody($event)"
              v-bind="cont.props"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <div app v-if="branding.footer && theme" class="mt-16 pa-12" style="background-color: rgb(30, 30, 30)" absolute>
      <v-row justify="center" v-for="(r, ri) in footer" :key="ri">
        <v-col v-for="(c, ci) in r" :key="ci" :class="c.classes">
          <component
            v-for="(cont, index) in c.elements"
            :key="index"
            :is="cont.component"
            @option="fireKey($event.key, stepOptionList[$event.key], $event.body, storedBody)"
            @next="fireKey('next', nextStep, $event, storedBody)"
            @logout="signOut"
            @trigger="fireKey('next', nextStep, $event, storedBody)"
            v-bind="cont.props"/>
        </v-col>
      </v-row>
      <v-row>
        <div class="body-2 white--text">{{branding.footer.providedBy.text}}</div>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

import {
  mapActions,
} from 'redux-vuex';

import envConfig from 'brand/env';
import Title from './components/Title.vue';
import Intro from './components/Intro.vue';
import ReferenceNumberForm from './components/ReferenceNumberForm.vue';
import ConfirmIdentity from './components/ConfirmIdentity.vue';
import InputForm from './components/InputForm.vue';
import InputMultiForm from './components/InputMultiForm.vue';
import OutstandingAmount from './components/OutstandingAmount.vue';
import OptionPay from './components/OptionPay.vue';
import PaymentPlanForm from './components/PaymentPlanForm.vue';
import PaymentFullForm from './components/PaymentFullForm.vue';
import PaymentPlanPreview from './components/PaymentPlanPreview.vue';
import List from './components/List.vue';
import PaymentList from './components/PaymentList.vue';
import Back from './components/Back.vue';
import Logout from './components/Logout.vue';
import CodeInputForm from './components/CodeInputForm.vue';
import CardButtons from './components/CardButtons.vue';
import InputCampaignTime from './components/InputCampaignTime.vue';
import InputFile from './components/InputFile.vue';
import InputMultiselect from './components/InputMultiselect.vue';
import Stepper from './components/Stepper.vue';
import InputListSimple from './components/InputListSimple.vue';
import InputList from './components/InputList.vue';
import InputButtonArray from './components/InputButtonArray.vue';
import InputMultiselectList from './components/InputMultiselectList.vue';
import InputMultiselectMergedList from './components/InputMultiselectMergedList.vue';
import InputConditions from './components/InputConditions.vue';
import HeroCard from './components/HeroCard.vue';
import DisplayCard from './components/DisplayCard.vue';
import widgetExec from './widgetExec';

import {
  findEnv,
} from './utils';

Vue.filter('numFormat', numFormat(numeral));

export default {
  name: 'App',
  methods: {
    ...mapActions(
      'endSession',
      'removePopup',
      'callApi',
      'addLoad',
      'removeLoad',
      'signOut',
      'fireKey',
    ),
    // /88-62-64.png
    checkValidity() {
      if (this.validityField === this.pageValidation) this.localValidation = true;
    },
    setFavicon(url) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = url;
    },
    storeLocalBody(event) {
      Object.assign(this.storedBody, event);
    },
  },
  components: {
    OutstandingAmount,
    OptionPay,
    Title,
    Intro,
    ReferenceNumberForm,
    ConfirmIdentity,
    InputForm,
    PaymentPlanForm,
    List,
    PaymentList,
    PaymentFullForm,
    Back,
    Logout,
    CodeInputForm,
    PaymentPlanPreview,
    CardButtons,
    InputCampaignTime,
    InputFile,
    InputMultiselect,
    Stepper,
    InputListSimple,
    InputList,
    InputButtonArray,
    InputMultiselectMergedList,
    InputMultiselectList,
    HeroCard,
    InputMultiForm,
    DisplayCard,
    InputConditions,
  },
  data() {
    return {
      dialog: false,
      localValidation: true,
      validityField: '',
      dialogItem: {},
      ...this.mapState({
        user: 'user',
        branding: 'branding',
        theme: 'theme',
        context: 'context',
        footer: 'footer',
        globalWorkCount: 'globalWorkCount',
        session: 'session',
        workflow: 'workflow',
        nextStep: 'nextStep',
        stepHistory: 'stepHistory',
        widget: 'widget',
        stepOptionList: 'stepOptionList',
        loadingReasons: 'loadingReasons',
        currentKey: 'currentKey',
        pageValidation: 'pageValidation',
        metaTags: 'metaTags',
        widgetFields: 'widgetFields',
      }),
      mini: true,
      defaultTheme: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      currentKey: null,
      storedBody: {},
    };
  },
  computed: {
    env() {
      return envConfig[findEnv(envConfig, window.top.location.origin)];
    },
  },
  watch: {
    pageValidation() {
      if (this.pageValidation) {
        this.localValidation = false;
      }
    },
    branding() {
      if (this.branding.favicon) {
        this.setFavicon(this.branding.favicon);
      }
    },
    theme() {
      this.$vuetify.theme.themes.light = {
        ...this.defaultTheme,
        ...this.theme,
      };
    },
    widget() {
      if (this.widget && this.widget.url) {
        widgetExec(window, document, this.widget.id, {}, this.widget.url);
      }
    },
    widgetFields() {
      Object.keys(this.widgetFields).forEach((f) => {
        /* eslint no-undef: "off" */
        console.log(f);
        yabbr(`field:${f}`, this.widgetFields[f]);
      });
    },
  },
  mounted() {
    this.$vuetify.theme.themes.light = {
      ...this.defaultTheme,
      ...this.theme,
    };
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
  body, .v-application, .v-application .text-h5 {
    font-family: 'Open Sans', sans-serif !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  // .theme--light.v-app-bar.v-toolbar.v-sheet {
  //   background: none !important;
  // }
  .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--light.v-application {
    background: #F9F9FA !important;
  }
  //
  // .theme--light.v-application.primary--text {
  //   color: white !important;
  // }
  .v-sheet.v-card.elevation-2 {
    border-radius: 8px !important;
    box-shadow: 0 5px 8px rgb(52 73 82 / 10%) !important;
  }

  .v-app-bar.elevation-2 {
    border-radius: 8px !important;
    box-shadow: 0 5px 8px rgb(52 73 82 / 10%) !important;
  }

  /* eslint max-len: "off" */
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }

  .v-application {
    .v-slider__thumb-label.primary {
      display: none;
      // border-radius: 200% 50% 0;
    }
  }

  .v-slider--horizontal .v-slider__track-container {
    height: 4px;
  }

  .v-btn, .v-tab {
  text-transform: none !important;
  letter-spacing: 0 !important;
  }

  .v-application h1 {
    font-size: 1.5rem;
  }

  .v-application .font-weight-bold {
    font-weight: 600 !important;
  }

  .v-application .font-weight-semibold {
    font-weight: 500 !important;
  }

  .v-application .v-card__title {
    letter-spacing: normal;
  }

  .v-overlay--active {
    backdrop-filter: blur(6px) !important;
  }

  .v-overlay__content {
    position: absolute !important;
    top: 150px !important;
  }

  @media print {
   /* styles for printing */
  }
</style>
