import moment from 'moment';
import envConfig from 'brand/env';

export const findEnv = (config, url) => {
  const urlParts = url.split('//')[1].split('.');

  return Object.keys(config)
    .find((v) => {
      const confParts = config[v].url.split('//')[1].split('.');

      let pass = true;
      confParts.forEach((c, i) => {
        console.log(c);
        if (c !== '*' && c !== urlParts[i]) {
          pass = false;
        }
      });

      return pass;
    }) || 'prod';
};

const tempIdPrefix = '';

export const randomHex = (len) => {
  const maxlen = 8;
  const min = 16 ** (Math.min(len, maxlen) - 1);
  const max = (16 ** Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);

  while (r.length < len) {
    r += randomHex(len - maxlen);
  }
  return r;
};

export const genTempID = (length = 20) => (tempIdPrefix + randomHex(length));

/* eslint prefer-template: "off" */
/* eslint no-bitwise: "off" */
/* eslint no-plusplus: "off" */
export const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const getWorkspaceId = () => localStorage.getItem('yabbr-organisation');

export const yabbrAuthRedirect = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  window.location.href = `${env.yabbrPortal}/#/?referrer=${encodeURIComponent(window.location.href)}&reason=get_tokens&samepage=true`;
};

export const getChangeWorkspaceLink = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  return `${env.yabbrPortal}/#/change-workspace?referrer=${encodeURIComponent(window.location.href)}&reason=change_workspace&samepage=true`;
};

export const nextProcessingDay = (date) => {
  const m = moment(date);
  const tomorrow = m.add(1, 'days');

  if (['Sa'].indexOf(tomorrow.format('dd')) !== -1) {
    return tomorrow.add(2, 'days').toDate();
  }

  if (['Su'].indexOf(tomorrow.format('dd')) !== -1) {
    return tomorrow.add(1, 'days').toDate();
  }

  return tomorrow.toDate();
};

/* eslint no-control-regex: off */
export const unicodeRegExp = /[^\x0a\x0d\x20-\x5F\x61-\x7E¡£¤¥§¿ÄÅÆÇÉÑÖØÜßàäåæèéìñòöøùüΓΔΘΛΞΠΣΦΨΩ€]/;
/* eslint no-useless-escape: off */
export const extendedRegExp = /[|^€{}~\\\[\]]/g;

export const smsCount = (message) => {
  let hasUnicode = false;
  let units = 0;

  if (!message) return { units, hasUnicode };

  if (message.match(unicodeRegExp)) {
    const length = message.length * 2;
    units = (length > 140 ? Math.ceil(length / 134) : 1);
    hasUnicode = true;
  } else {
    const extended = message.match(extendedRegExp);
    const length = message.length + (extended ? extended.length : 0);
    units = (length > 160 ? Math.ceil(length / 153) : 1);
  }

  return { units, hasUnicode };
};
