<template>
  <div class="mb-4">
    <!-- <div class="mb-2">Select an option</div> -->
    <div class="d-flex flex-column">
      <div
        class="rounded-lg mb-3 card-button theme--light primary--text v-btn v-btn--outlined"
        v-for="(item, index) in items"
        @keyup="handleClick(item)"
        @click="handleClick(item)"
        :key="index">
        <v-card-title class="font-weight-bold pb-2 mb-0 mt-0 pt-2 px-4">
          {{item.title}}
          <v-spacer></v-spacer>
          <v-icon :color="item.color || 'primary'" class="ml-2">mdi-arrow-right-circle</v-icon>
        </v-card-title>
        <v-card-text class="px-4 pb-4 pt-0 mt-0" v-if="item.subtitle">{{item.subtitle}}</v-card-text>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import get from 'lodash/get';

export default {
  name: 'CardButtons',
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    prompt: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: () => 'primary',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    reason: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    moment,
    itemClickable(item) {
      return !!item.action || !!item.option || !!item.href;
    },
    handleClick(item) {
      if (item.action === 'trigger-widget-open') {
        window.openYabbrWidget();
        return false;
      }

      if (item.action) {
        this.$emit(item.action.type);
        return null;
      }

      if (item.option) {
        const opt = {
          key: item.option,
          body: {
            form: item.option,
            option: item.option,
            friendly: item.title,
            reason: this.reason,
            instanceKey: this.instanceKey,
          },
        };

        if (item.localState) {
          Object.keys(item.localState).forEach((ls) => {
            opt.body[ls] = get(this, item.localState[ls]);
          });
        }
        this.$emit('option', opt);
        return null;
      }

      return null;
    },
    getHref(item) {
      if (!item.href) return false;
      return `${item.href}`;
    },
  },
  data() {
    return {
      ...this.mapState('localContext'),
    };
  },
};
</script>
<style lang="scss" scoped>
  @import '~vuetify/src/styles/styles.sass';

  .card-button {
    display: initial !important;
    white-space: wrap;
    background-color: initial !important;
    cursor: pointer;
  }
</style>
