import actions from '../actions';

import {
  executeStep,
} from '../endpoints';

const callTriggerMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  if ([
    'CALL_END',
  ].indexOf(action.type) === -1) return;
  if (action.error) return;

  if (!action.payload.meta.ok) {
    dispatch(actions.signOut());
    return;
  }

  let returnVal = null;
  if (action.payload.data.result) {
    returnVal = action.payload.data.result.return;
  } else if (action.payload.data.return) {
    returnVal = action.payload.data.return;
  } else {
    returnVal = action.payload.data;
  }

  if (!action.payload.call.noContentType && (!returnVal || Object.keys(returnVal).length === 0)) {
    dispatch(actions.signOut());
    return;
  }

  console.log(returnVal);

  if (returnVal.state) {
    if (returnVal.state.redirectStep) {
      dispatch(actions.setRedirectStep(returnVal.state.redirectStep));
    }

    if (returnVal.state.gotoUrl) {
      window.location.href = returnVal.state.gotoUrl;
      return;
    }

    if (returnVal.state.logout) {
      dispatch(actions.signOut());
      return;
    }

    dispatch(actions.dispatchActions(returnVal.state));
  }

  if (action.payload?.call?.step && !action.payload?.call?.ignore) {
    dispatch(actions.addStep({ step: action.payload.call.step, request: action.payload }));
  }

  if (returnVal?.execKeys?.repeat) {
    dispatch(actions.addLoad());
    await dispatch(actions.callApi(executeStep(
      getState().workflow.url,
      returnVal.execKeys.repeat,
      getState().stepHistory[(getState().stepHistory.length - 2 || 0)]?.request?.call?.data,
    )));

    dispatch(actions.removeLoad());
    return;
  }

  if (returnVal?.execKeys?.trigger) {
    await dispatch(actions.fireKey('trigger', returnVal.execKeys.trigger, returnVal.payload));
    return;
  }

  const options = {};
  Object.keys(returnVal?.execKeys || []).forEach((e) => {
    if (e.startsWith('option-')) {
      options[e.substring(7, e.length)] = returnVal.execKeys[e];
    }
  });

  if (Object.keys(options).length) {
    dispatch(actions.stepOptions(options));
  }

  if (!returnVal.execKeys) return;

  if (returnVal.execKeys.next) {
    dispatch(actions.stepNext(returnVal.execKeys.next));
  }

  if (returnVal.execKeys.back) {
    dispatch(actions.stepBack(returnVal.execKeys.back));
  }
};

export default callTriggerMiddleware;
