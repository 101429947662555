<template>
  <div>
    <!-- <div class="mb-2 ml-3 text-body-2">
      Payment Options
    </div> -->
  <v-card class="rounded-lg mb-10 elevation-2" color="primary" dark>
    <!-- <v-card-title class="font-weight-bold pb-0">
      Setup a Payment
    </v-card-title> -->
    <!-- <v-card-text>
      Choose a payment method that makes sense for you
    </v-card-text> -->
    <!-- <v-divider></v-divider> -->
    <v-list-item
      v-if="optionPA && (daysToClose >= 8 )"
      class="mt-0 pl-2"
      two-line
      subheader
      @click="$emit('option', {key: 'pa', body: {}})">
      <v-list-item-avatar class="mr-2">
        <v-icon>
          mdi-calendar-refresh
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          Setup a Payment Plan
        </v-list-item-title>
        <v-list-item-subtitle>{{optionPAText}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-list-item-avatar>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item
      v-if="optionFull"
      class="mt-0  pl-2"
      two-line
      subheader
      @click="$emit('option', {key: 'pif', body: {}})">
      <v-list-item-avatar class="mr-2">
        <v-icon>
          mdi-cash-fast
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          Pay in Full
        </v-list-item-title>
        <v-list-item-subtitle>{{optionFullText}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-list-item-avatar>
    </v-list-item>

    <!-- <v-card-actions class="px-12 pb-8">
      <v-btn
        block
        large
        @click="$emit('trigger', { option: 'pay-pa' })"
        :class="{'black--text': branding.primaryButtons.darkText}"
        v-bind="branding.primaryButtons">
        Set up a payment plan
      </v-btn>
    </v-card-actions> -->
  </v-card>
</div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OptionPay',
  props: {
    optionPA: {
      type: Boolean,
      default: () => true,
    },
    optionPAText: {
      type: String,
      default: () => 'Configure instalments that work for you',
    },
    optionFull: {
      type: Boolean,
      default: () => true,
    },
    optionFullText: {
      type: String,
      default: () => 'Immediatley pay the amount outstanding in full',
    },
    maxArrangementLength: {
      type: Number,
      default: () => 1,
    },
    daysToFirstPayment: {
      type: Number,
      default: () => 1,
    },
    maxPADate: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    localCapDate() {
      const maLengthDate = moment().add(this.maxArrangementLength, 'months');
      console.log(maLengthDate);
      if (this.maxPADate && moment(this.maxPADate).isBefore(maLengthDate)) return moment(this.maxPADate);
      return maLengthDate;
    },
    daysToClose() {
      return this.localCapDate.diff(moment(), 'days');
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
    };
  },
};
</script>
