<template>
  <v-card class="rounded-lg mb-sm-10 mb-4 elevation-2">
    <v-card-title class="font-weight-bold pb-0">
      {{title}},
    </v-card-title>
    <v-card-text>
      {{subtitle}}
    </v-card-text>
    <v-divider class="mb-0"></v-divider>
    <v-list-item class="mt-0" two-line>
      <v-list-item-avatar>
        <v-icon>
          mdi-currency-usd
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <strong>{{debtValue}}</strong>
        </v-list-item-title>
        <v-list-item-subtitle>In arrears</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item class="mt-0" two-line>
      <v-list-item-avatar>
        <v-icon>
          mdi-calendar
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{dueDate}}
        </v-list-item-title>
        <v-list-item-subtitle>Original due date</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>

export default {
  name: 'OutstandingAmount',
  props: {
    debtValue: {
      type: Number,
      default: () => 0,
    },
    title: {
      type: String,
      default: () => 'Title',
    },
    subtitle: {
      type: String,
      default: () => 'subtitle',
    },
    dueDate: {
      type: String,
      default: () => '15/15/15',
    },
  },
  data() {
    return {
      ...this.mapState(),
    };
  },
};
</script>
