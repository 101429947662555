<template>
  <div class="">
    <form
      v-if="!isWorking"
      novalidate
      @submit.prevent="submit">
      <v-card class="rounded-lg mb-sm-10 mb-4 mt-3 elevation-2">
        <div class="mx-12 pt-3">
          <div class="d-flex justify-center flex-wrap">
            <v-btn
              v-for="(item, index) in items"
              :key="index"
              class="ma-1"
              v-bind="branding.secondaryButtons"
              :outlined="!localItems.includes(item.id)"
              @click="toggleItem(item)">
              {{ item.text }}
            </v-btn>
          </div>
        </div>
        <v-card-actions class="pl-6 pr-6 pb-6 pt-2">
          <v-btn
            type="submit"
            block
            x-large
            class="font-weight-bold"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            Continue
            <v-icon size="16">
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'InputButtonArray',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState('branding', 'stepOptionList'),
      isWorking: false,
      localItems: [],
    };
  },
  methods: {
    moment,
    toggleItem(item) {
      const ind = this.localItems.indexOf(item.id);
      if (ind === -1) {
        this.localItems.push(item.id);
        return;
      }

      this.localItems.splice(ind, 1);
    },
    submit() {
      this.isWorking = true;
      this.$emit('trigger', { form: this.localItems, instanceKey: this.instanceKey });
    },
  },
  mounted() {
    this.localItems = cloneDeep(this.selected);
  },
};
</script>
