<template>
  <div class="">
    {{text}}
  </div>
</template>

<script>

export default {
  name: 'Intro',
  props: {
    text: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState(),
    };
  },
};
</script>
