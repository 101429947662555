<template>
  <div class="">
    <!-- <div class="mb-2 ml-3 text-body-2">
      Create a Payment Arrangement
    </div> -->
    <v-container class="mt-3" v-if="isWorking">
      <v-row justify="center">
        <v-col md="6" col="12">
          <v-skeleton-loader
            type="table-heading, list-item-two-line">
          </v-skeleton-loader>
          <v-skeleton-loader
            class="mt-4"
            type="list-item-three-line">
          </v-skeleton-loader>
          <v-skeleton-loader
            class="mt-4"
            type="article, actions">
          </v-skeleton-loader>
          <v-skeleton-loader
            class="mt-4"
            type="list-item-three-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <form
      v-if="!isWorking"
      novalidate
      @submit.prevent="submit">
      <v-card class="rounded-lg mb-sm-10 mb-4 pt-3 elevation-2">
        <div class="">
          <v-card-text class="text-center mb-1 pb-0 font-weight-bold" v-if="enabledPayments.length > 1">
            How often would you like to pay?
          </v-card-text>
          <v-card-text class="text-center mb-1 pb-0 font-weight-bold" v-if="enabledPayments.length === 1">
            {{enabledPayments[0].name}} payments of...
          </v-card-text>
        </div>

        <div class="d-flex justify-center" v-if="enabledPayments.length > 1">
          <v-btn-toggle
            class="mt-2 mb-2"
            mandatory
            v-model="form.frequencyCode"
            @change="updateContext"
            :class="{'black--text': branding.secondaryButtons.darkText}"
            v-bind="branding.secondaryButtons" borderless>

            <v-btn
              :value="item.value"
              class="font-weight-bold"
              :key="index"
              v-for="(item, index) in enabledPayments">
              {{item.name}}
            </v-btn>
          </v-btn-toggle>
        </div>

        <div class="text-center mt-6 text-h3" color="primary">
          ${{ sliderModel[form.frequencyCode] }}
        </div>

        <v-slider
          :disabled="!guides[form.frequencyCode].hasSlider"
          class="mx-12 mb-0 pb-0"
          v-model="sliderModel[form.frequencyCode]"
          :min="guides[form.frequencyCode].min"
          :max="guides[form.frequencyCode].max"
          @change="updateContext">
          <template v-slot:thumb-label="{ value }">
            <div class="text-body-1 font-weight-bold">${{ value }}</div>
          </template>
        </v-slider>

        <div class="" style="margin-top: -20px;">
          <v-card-text class="text-center mt-0 pt-0 mb-0 pb-0">
            You will pay ${{debtValue}} over
            <span class="primary--text font-weight-bold">{{repaymentTime}} {{guides[form.frequencyCode].plural}}</span>
          </v-card-text>
          <v-card-text class="text-center mb-1 pb-0 mt-0 pt-0 font-weight-bold" v-if="availableStartDates.length <= 1">
            Starting on {{availableStartDates[0].format('ddd, MMM DD')}}
          </v-card-text>
        </div>
        <div class="mx-6 pt-3">
          <v-card-text class="text-center mb-1 pb-0 font-weight-bold" v-if="availableStartDates.length > 1">
            When would you like to start?
          </v-card-text>
          <div class="d-flex justify-center flex-wrap" v-if="availableStartDates.length > 1">
            <v-btn
              v-for="(item, index) in availableStartDates"
              :key="index"
              class="ma-1"
              v-bind="branding.secondaryButtons"
              :outlined="$v.form.commencementDate.$model !== item.format('YYYY-MM-DD')"
              @click="$v.form.commencementDate.$model = item.format('YYYY-MM-DD'); updateContext();">
              {{item.format('ddd, MMM DD')}}
            </v-btn>
          </div>
        </div>
        <div class="mt-4">
          <v-card-text v-if="paymentMethodsLocal.length > 1" class="text-center mb-0 pb-0 font-weight-bold">
            How would you like to pay?
          </v-card-text>
        </div>
        <div class="mx-12 mt-2 mb-6 d-flex justify-center">
          <div class="d-flex justify-center">
            <v-btn-toggle
              v-if="paymentMethodsLocal.length > 1"
              v-model="$v.form.paymentMethod.$model"
              mandatory
              :class="{'black--text': branding.secondaryButtons.darkText}"
              v-bind="branding.secondaryButtons"
              borderless>
              <v-btn
                :value="item.value"
                class="px-6"
                :key="index"
                v-for="(item, index) in paymentMethodsLocal">
                <div class="d-flex">
                  <div class="mr-1">
                    <v-icon dense>
                      {{item.icon}}
                    </v-icon>
                  </div>
                  <div class="font-weight-bold align-self-center">
                    {{item.name}}
                  </div>
                </div>
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <v-card-actions class="pl-6 pr-6 pb-6 pt-2">
          <v-btn
            type="submit"
            block
            x-large
            class="font-weight-bold"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <span v-if="cta === 'proceed'">Proceed to Pay</span>
            <span v-if="cta === 'summary'">
              Pay ${{sliderModel[form.frequencyCode]}} {{guides[form.frequencyCode].name}}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';

import {
  mapActions,
} from 'redux-vuex';

import { nextProcessingDay } from '../utils';

export default {
  name: 'PaymentPlanForm',
  mixins: [validationMixin],
  props: {
    maxArrangementLength: {
      type: Number,
      default: () => 1,
    },
    daysToFirstPayment: {
      type: Number,
      default: () => 1,
    },
    debtValue: {
      type: Number,
      default: () => 1,
    },
    defaultInterval: {
      type: String,
      default: () => 'weekly',
    },
    commencementDate: {
      type: Date,
      default: () => nextProcessingDay(new Date()),
    },
    paymentMethods: {
      type: Array,
      default: () => [2, 3],
    },
    cta: {
      type: String,
      default: () => 'summary',
    },
    maxPADate: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    guides() {
      return {
        2: {
          value: 2,
          enabled: this.maxArrangementLengthInDays >= this.arrangementWindow[2],
          hasSlider: true,
          name: 'Weekly',
          plural: 'weeks',
          min: Math.ceil(this.localDebtValue / this.minArrangementLookup.weekly),
          max: Math.ceil(this.localDebtValue / 2),
        },
        // 3: {
        //   value: 3,
        //   enabled: this.maxArrangementLengthInDays >= this.arrangementWindow[3],
        //   name: 'Fortnightly',
        //   plural: 'fortnights',
        //   hasSlider: this.localMaxArrangementLength > 1,
        //   min: Math.ceil(this.localDebtValue / this.minArrangementLookup.fortnightly),
        //   max: Math.ceil(this.localDebtValue / 2),
        // },
        4: {
          value: 4,
          name: 'Monthly',
          plural: 'months',
          enabled: this.localMaxArrangementLength >= this.arrangementWindow[4],
          hasSlider: this.localMaxArrangementLength > 1,
          min: Math.ceil(this.localDebtValue / this.minArrangementLookup.monthly),
          max: Math.ceil(this.localDebtValue / 2),
        },
      };
    },
    localCapDate() {
      const maLengthDate = moment().add(this.maxArrangementLength, 'months');
      if (this.maxPADate && moment(this.maxPADate).isBefore(maLengthDate)) return moment(this.maxPADate);
      return maLengthDate;
    },
    maxArrangementLengthInDays() {
      return this.localCapDate.diff(moment(this.form.commencementDate), 'days');
    },
    paymentMethodsLocal() {
      return [
        {
          value: 2,
          name: 'Debit Card',
          icon: 'mdi-credit-card-outline',
        },
        {
          value: 3,
          name: 'Direct Debit',
          icon: 'mdi-bank',
        },
      ].filter((r) => this.paymentMethods.indexOf(r.value) !== -1);
    },
    enabledPayments() {
      return Object.values(this.guides).filter((f) => f.enabled);
    },
    repaymentTime() {
      if (!this.guides[this.form.frequencyCode] || !this.sliderModel[this.form.frequencyCode]) return 0;
      const paymentNum = Math.ceil(this.localDebtValue / this.sliderModel[this.form.frequencyCode]);
      switch (this.form.frequencyCode) {
        case 'Weekly':
          return paymentNum;
        case 'Fortnightly':
          return paymentNum * 2;
        case 'Monthly':
          return paymentNum;
        default:
          return paymentNum;
      }
    },
    minArrangementLookup() {
      return {
        weekly: this.maxArrangementLengthInDays / 8,
        // fortnightly: this.maxArrangementLengthInDays / 15,
        monthly: this.maxArrangementLengthInDays / 31,
      };
    },
    availableStartDates() {
      const dateArray = [];
      const finalArray = [];

      for (let i = 0; i < this.localDaysToFirstPayment; i += 1) {
        const d = moment(this.commencementDate).add(i, 'days');

        if ((['Mo', 'Tu', 'We', 'Th', 'Fr'].indexOf(d.format('dd')) !== -1) && this.isPaStartValid(d.toDate(), this.form.frequencyCode)) {
          dateArray.push(d);
        }
      }

      dateArray.forEach((item, index) => {
        if (!(index % (Math.floor(dateArray.length * 0.2) || 1))) {
          finalArray.push(item);
        }
      });

      return finalArray;
    },
  },
  data() {
    return {
      ...this.mapState('branding', 'stepOptionList'),
      form: {
        paymentPlanAmount: null,
        frequencyCode: 2,
        commencementDate: null,
        paymentMethod: 2,
        paymentType: 2,
      },
      sliderModel: {
        2: 1,
        3: 1,
        4: 1,
      },
      arrangementWindow: {
        2: 8,
        3: 15,
        4: 32,
      },
      localDaysToFirstPayment: 1,
      localDebtValue: 1,
      isWorking: false,
    };
  },
  validations: {
    form: {
      paymentPlanAmount: {
        required,
      },
      frequencyCode: {
        required,
      },
      commencementDate: {
        required,
      },
      paymentMethod: {
        required,
      },
    },
  },
  methods: {
    moment,
    ...mapActions(
      'setLocalContext',
    ),
    isPaStartValid(startDate, frequencyCode) {
      return moment(startDate).add(this.arrangementWindow[frequencyCode], 'days').isSameOrBefore(this.localCapDate);
    },
    async updateContext() {
      await this.$nextTick();
      this.setLocalContext({ prop: 'repaymentValue', val: this.sliderModel[this.form.frequencyCode] });
      this.setLocalContext({ prop: 'interval', val: this.guides[this.form.frequencyCode].plural });
      this.setLocalContext({ prop: 'commencementDate', val: this.form.commencementDate });
      this.setLocalContext({ prop: 'debtValue', val: this.debtValue });
      this.setLocalContext({ prop: 'repaymentTime', val: this.repaymentTime });
      this.setLocalContext({ prop: 'minMonthly', val: this.guides[4].min });
    },
    submit() {
      this.form.paymentPlanAmount = this.sliderModel[this.form.frequencyCode];
      this.form.returnURLSuccess = `${window.location.href}?s=${this.stepOptionList.success}`;
      this.form.returnURLFailure = `${window.location.href}?s=${this.stepOptionList.failure}`;
      this.form.returnURLToWebsite = `${window.location.href}?s=${this.stepOptionList.return}`;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      this.isWorking = true;
      this.$emit('next', this.form);
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
  mounted() {
    this.localMaxArrangementLength = Number(this.maxArrangementLength);
    this.localDaysToFirstPayment = Number(this.daysToFirstPayment);
    this.localDebtValue = Number(this.debtValue);
    this.form.commencementDate = moment(this.commencementDate).format('yyyy-MM-DD');

    Object.keys(this.guides).forEach((g) => {
      this.sliderModel[g] = Math.ceil((this.guides[g].max - this.guides[g].min) / 2) + this.guides[g].min;
    });

    this.updateContext();
  },
};
</script>
