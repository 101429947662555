const saveStoreMiddleware = (store) => (next) => (action) => {
  next(action);

  if (['SET_SESSION', 'ADD_STEP', 'SET_WORKFLOW', 'SET_REDIRECT_STEP'].indexOf(action.type) === -1) return;
  if (store.getState().session && store.getState().session.clearStore) {
    localStorage.removeItem('yabbr-wizard-store');
    return;
  }

  localStorage.setItem('yabbr-wizard-store', JSON.stringify(store.getState()));
};

export default saveStoreMiddleware;
