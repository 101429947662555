<template>
  <div class="">
    <form
      v-if="!isWorking"
      novalidate
      @submit.prevent="submit">
      <v-card class="rounded-lg mb-sm-10 mb-5 elevation-2">
        <div class="mx-5 pt-6">
          <div class="d-flex flex-wrap">
            <v-btn
              v-for="(item, index) in availableStartDates"
              :key="index"
              class="ma-1"
              v-bind="branding.secondaryButtons"
              :outlined="$v.form.dueBy.$model !== item.format('YYYY-MM-DD')"
              @click="$v.form.dueBy.$model = item.format('YYYY-MM-DD');">
              {{item.format('ddd, MMM DD')}}
            </v-btn>
          </div>
        </div>
        <div class="mt-5" v-if="allowNotes">
          <v-card-text class="text-center mb-0 pb-0 font-weight-bold">
            Do you have any notes on timeframes or require specific documentation?
          </v-card-text>
        </div>
        <v-textarea
          v-if="allowNotes"
          class="mt-4 mx-4"
          :type="inputType"
          v-model="$v.form.comments.$model"
          :label="inputLabel"
          required
          outlined
          dense>
        </v-textarea>
        <v-card-actions class="pl-12 pr-12 pb-6 pt-10">
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            :disabled="!$v.form.dueBy.$model"
            class="font-weight-bold"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            Continue
            <v-icon size="22" class="ml-2 mt-1">
              mdi-arrow-right-circle
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';

import { timeslots } from '../config';

export default {
  name: 'InputCampaignTime',
  mixins: [validationMixin],
  props: {
    daysToFirstPayment: {
      type: Number,
      default: () => 7,
    },
    dueBy: {
      type: Date,
      default: () => null,
    },
    allowNotes: {
      type: Boolean,
      default: () => false,
    },
    dowLimit: {
      type: Array,
      default: () => ['Mo', 'Tu', 'We', 'Th', 'Fr'],
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    startOffset: {
      type: Number,
      default: () => 2,
    },
    reason: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState('branding', 'stepOptionList'),
      form: {
        paymentPlanAmount: null,
        frequencyCode: 1,
        dueBy: null,
        paymentMethod: 2,
        paymentType: 2,
      },
      today: moment(),
      localDaysToFirstPayment: 7,
      isWorking: false,
      timeslots,
    };
  },
  validations: {
    form: {
      paymentPlanAmount: {
        required,
      },
      dueBy: {
        required,
      },
      comments: {
        required,
      },
      paymentMethod: {
        required,
      },
    },
  },
  computed: {
    availableStartDates() {
      const dateArray = [];

      const offsetDate = this.today.add(this.startOffset, 'days');

      for (let i = 0; i < this.localDaysToFirstPayment; i += 1) {
        const d = moment(offsetDate).add(i, 'days');

        if ((this.dowLimit.indexOf(d.format('dd')) !== -1)) {
          dateArray.push(d);
        }
      }

      return dateArray;
    },
  },
  methods: {
    moment,
    submit() {
      this.isWorking = true;
      this.$emit('trigger', {
        form: this.form,
        instanceKey: this.instanceKey,
        reason: this.reason,
        friendly: this.$v.form.dueBy.$model,
      });
    },
  },
  mounted() {
    this.localDaysToFirstPayment = Number(this.daysToFirstPayment);
    if (this.dueBy) {
      this.form.dueBy = moment(this.dueBy).format('yyyy-MM-DD');
    }
  },
};
</script>
<style lang="scss">
/* Set the theme color of the component */
$themeColor: red;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
