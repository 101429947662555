<template>
  <div class="">
    <v-card
      class="rounded-lg mb-2 elevation-2 mr-4 card-button"
      style="width: 500px;">
      <div class="d-flex justify-center pt-8">
        <v-icon size="100" :color="color">
          {{icon}}
        </v-icon>
      </div>
      <v-card-title class="font-weight-bold text-center d-flex justify-center text-wrap break-word">
        {{title}}
      </v-card-title>
      <v-card-text class="text-center pb-8 px-10">
        {{subtitle}}
      </v-card-text>
      <v-card-actions class="px-10 pb-5" v-if="nextAction">
        <v-btn
          @click="submit"
          block
          x-large
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="16">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'HeroCard',
  props: {
    icon: {
      type: String,
      default: () => 'mdi-check-circle',
    },
    items: {
      type: Array,
      default: () => [],
    },
    prompt: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: () => 'primary',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    nextAction: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    moment,
    submit() {
      this.$emit('trigger', { form: {}, instanceKey: this.instanceKey });
    },
  },
  data() {
    return {
      ...this.mapState('localContext', 'branding'),
    };
  },
};
</script>
<style lang="scss" scoped>
  @import '~vuetify/src/styles/styles.sass';
  .break-word {
    word-break: break-word !important;
  }
  .card-button {
    &:hover {
      background-color: var(--v-primary-lighten1) !important;
    }
  }
</style>
