export const startWorkflow = (url, id, data, config = {}) => ({
  endpoint: `/start/${id}`,
  method: 'POST',
  url,
  data,
  ...config,
});

export const whoAmI = (domain, loc = 'dms', config = {}) => ({
  endpoint: `/namespaces?domain=${domain}`,
  method: 'GET',
  loc,
  apiResource: 'dms',
  ...config,
});

export const executeStep = (url, execKey, data, config = {}) => ({
  endpoint: `/execute/${execKey}`,
  method: 'POST',
  data,
  url,
  step: execKey,
  ...config,
});

export const deleteStep = (url, execKey, data, config = {}) => ({
  endpoint: `/execute/${execKey}`,
  method: 'DELETE',
  data,
  url,
  ...config,
});

export const postFile = (url, data, config = {}) => ({
  customEndpoint: url,
  noContentType: true,
  method: 'POST',
  data,
  rawBody: true,
  ...config,
});
