<template>
  <div>
    <v-card
      class="rounded-lg mb-sm-10 mb-4"
      :class="{ 'mt-3': bgColour == 'initial' }"
      :elevation="elevation"
      :outlined="outlined"
      :dark="darkMode"
      :style="`background-color: ${bgColour};`">
      <v-card-title class="font-weight-bold pb-3 text-h7" :class="{'px-5': !icon}" v-if="title">
        <v-icon :color="color" v-if="icon" class="mr-4">{{icon}}</v-icon>
        {{title}}
      </v-card-title>
      <v-card-text :class="{'px-5': !icon, 'pl-14 pr-7': icon}"  v-if="subtitle">
        {{subtitle}}
      </v-card-text>
      <v-divider class="mb-0" v-if="title || subtitle"></v-divider>
      <div class="" v-for="(item, index) in items" :key="index">
        <div class="d-flex" v-if="item.paragraph">
          <div class="" :class="{'mt-1': dense, 'mt-4': !dense, 'ml-2': bgColour == 'initial', 'ml-0': bgColour != 'initial' }">
            <v-icon :color="item.color ? item.color : ''" v-if="item.icon" class="mr-1 ml-4">{{item.icon}}</v-icon>
          </div>
          <div class="">
            <v-card-title v-if="item.title" class="pb-1 ml-0 pl-4" :class="{'px-5': !icon}">
              {{item.title}}
            </v-card-title>
            <v-card-text :class="{'font-weight-bold': item.bold, 'py-2': dense}">
              {{item.subtitle}}
            </v-card-text>
          </div>
        </div>
        <v-list-item class="mt-0 pl-2"
          :dense="dense"
          v-if="!item.paragraph && (item.action || item.option || item.href || item.key || globalKey)"
          :two-line="!item.lineCount || item.lineCount === 2"
          :three-line="item.lineCount === 3"
          :href="getHref(item)"
          :target="item.href ? '_blank' : '_self'"
          @click="handleClick(item, index)">
          <v-list-item-avatar class="mr-2" v-if="item.icon">
            <v-icon :color="item.color ? item.color : ''">
              {{item.icon}}
            </v-icon>
          </v-list-item-avatar>
          <div v-if="!item.icon" class="ml-2"></div>
          <v-list-item-content>
            <v-list-item-title v-if="item.title" :class="{'font-weight-bold text-h5': item.highlight}">
              <span v-if="item.display === 'date'">
                {{ moment(item.title).format(item.format) }}
              </span>
              <span v-if="!item.display">
                {{item.title}}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle && item.subtitle.length">
              {{getContent(item.subtitle)}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar v-if="itemClickable(item)">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item class="mt-0 pl-2"
          :dense="dense"
          v-if="!item.paragraph && !item.action && !item.option && !item.href && !item.key && !globalKey"
          :two-line="!item.lineCount || item.lineCount === 2"
          :three-line="item.lineCount === 3">
          <v-list-item-avatar v-if="item.icon" class="mr-0">
            <v-icon :color="item.color ? item.color : ''">
              {{item.icon}}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="ml-2">
            <v-list-item-title v-if="item.title" :class="{'font-weight-bold text-h5': item.highlight}">
              <span v-if="item.display === 'date'">
                {{ moment(item.title).format(item.format) }}
              </span>
              <span v-if="!item.display">
                {{item.title}}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle && item.subtitle.length">
              {{getContent(item.subtitle)}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar v-if="itemClickable(item)">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-divider v-if="index < items.length - 1 && divided"></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import get from 'lodash/get';

export default {
  name: 'List',
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    prompt: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: () => '',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    darkMode: {
      type: Boolean,
      default: () => false,
    },
    elevation: {
      type: Number,
      default: () => 2,
    },
    outlined: {
      type: Boolean,
      default: () => false,
    },
    divided: {
      type: Boolean,
      default: () => true,
    },
    minLength: {
      type: Number,
      default: () => 0,
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    bgColour: {
      type: String,
      default: () => 'white',
    },
    globalKey: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    paddingElements() {
      return [];
    },
  },
  methods: {
    moment,
    getContent(content) {
      if (!Array.isArray(content)) {
        return content;
      }

      return content.join(' ');
    },
    itemClickable(item) {
      return !!item.action || !!item.option || !!item.href || !!item.key || !!this.globalKey;
    },
    handleClick(item, index) {
      console.log(item);
      if (item.action === 'trigger-widget-open') {
        window.openYabbrWidget();
        return false;
      }

      if (item.action) {
        this.$emit(item.action.type);
        return null;
      }

      if (item.option) {
        const opt = {
          key: item.option,
          body: {
            index,
            option: item.option,
            instanceKey: this.instanceKey,
            form: item.option,
          },
        };

        if (item.localState) {
          Object.keys(item.localState).forEach((ls) => {
            opt.body[ls] = get(this, item.localState[ls]);
          });
        }
        this.$emit('option', opt);
        return null;
      }

      if (item.key || this.globalKey) {
        const key = item.key || this.globalKey;
        console.log(key);
        const opt = {
          key,
          body: {
            key,
            index,
          },
        };

        this.$emit('key-fire', opt);
        return null;
      }

      return null;
    },
    getHref(item) {
      if (!item.href) return false;
      return `${item.href}`;
    },
  },
  data() {
    return {
      ...this.mapState('localContext'),
    };
  },
};
</script>
