import { combineReducers } from 'redux';

export default combineReducers({
  /* eslint default-param-last: "off" */
  globalWorkCount: (state = 0, action) => {
    switch (action.type) {
      case 'ADD_LOAD':
        return state + 1;
      case 'REMOVE_LOAD':
        return state > 1 ? state - 1 : 0;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  localContext: (state = {}, action) => {
    switch (action.type) {
      case 'SET_LOCAL_CONTEXT':
        return {
          ...state,
          [action.payload.prop]: action.payload.val,
        };
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  currentKey: (state = {}, action) => {
    switch (action.type) {
      case 'SET_CURRENT_KEY':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  loadingReasons: (state = {}, action) => {
    switch (action.type) {
      case 'SET_LOADING_REASONS':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  widgetFields: (state = null, action) => {
    switch (action.type) {
      case 'SET_WIDGET_FIELDS':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  footer: (state = null, action) => {
    switch (action.type) {
      case 'SET_FOOTER':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  context: (state = null, action) => {
    switch (action.type) {
      case 'SET_CONTEXT':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  theme: (state = null, action) => {
    switch (action.type) {
      case 'SET_THEME':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  metaTags: (state = {}, action) => {
    switch (action.type) {
      case 'SET_META_TAGS':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  pageValidation: (state = {}, action) => {
    switch (action.type) {
      case 'SET_PAGE_VALIDATION':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  branding: (state = {}, action) => {
    switch (action.type) {
      case 'SET_BRANDING':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  session: (state = null, action) => {
    switch (action.type) {
      case 'SET_SESSION':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  workflow: (state = {}, action) => {
    switch (action.type) {
      case 'SET_WORKFLOW':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  widget: (state = {}, action) => {
    switch (action.type) {
      case 'SET_WIDGET':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  stepHistory: (state = [], action) => {
    switch (action.type) {
      case 'ADD_STEP':
        return [...state, action.payload];
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  nextStep: (state = null, action) => {
    switch (action.type) {
      case 'NEXT_STEP':
        return action.payload;
      default:
        return state;
    }
  },
  /* eslint default-param-last: "off" */
  backStep: (state = null, action) => {
    switch (action.type) {
      case 'BACK_STEP':
        return action.payload;
      default:
        return state;
    }
  },
  stepOptionList: (state = {}, action) => {
    switch (action.type) {
      case 'STEP_OPTIONS':
        return action.payload;
      default:
        return state;
    }
  },
  redirectStep: (state = null, action) => {
    switch (action.type) {
      case 'SET_REDIRECT_STEP':
        return action.payload;
      default:
        return state;
    }
  },
});
