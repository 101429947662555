<template>
  <div class="mb-6">
    <div class="mb-6 d-flex" style="margin-top: -60px;">
        <v-spacer/>
        <v-btn
          @click="submit"
          :disabled="!listIsValid"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon class="ml-2">
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </div>
  <div v-for="(v, index) in parentsAsArray"
        :key="`item-${index}`">
      <v-card
        class="px-1 rounded-lg elevation-2 mb-3"
        :class="{'mb-4': !index, 'my-4': index}">
        <v-card-title>
          <v-icon class="mr-1" v-if="v.parent || v.parent === 0">
            mdi-arrow-right-bottom
          </v-icon>
          <span>{{ inputLabel }} {{ getAdjustedLabel(index) }}</span>
          <v-spacer/>
          <v-btn
            icon
            text
            v-if="parentsAsArray.length > 1"
            @click="removeItemConfirm(v.id)"
            :class="{'black--text': branding.primaryButtons.darkText}"
            class="mr-2"
            v-bind="branding.primaryButtons">
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            v-if="editMode !== v.id"
            @click="editMode = v.id;"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            v-if="editMode === v.id"
            @click="editMode = -1"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <v-icon>
              mdi-content-save-outline
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="editMode !== v.id && !v.value" class="text-body-1"><i>Add your Question Content</i></div>
          <div v-if="editMode !== v.id && v.value" class="text-body-1 mb-4">{{v.value}}</div>
          <!-- <div class="mt-3" v-if="editMode !== index && questionPostPend">{{questionPostPend}}</div> -->
          <v-textarea
            v-if="editMode === v.id"
            v-model="localItems[v.id].value"
            :label="`${inputLabel} Content`"
            :error-messages="getErrors('itemcontent', v.value)"
            required
            outlined
            rows="1"
            hint="Add the question only. The response prompts will be added automatically from your Response Options below."
            counter
            dense>
          </v-textarea>
          <v-subheader v-if="v.responses.length || editMode === v.id" class="ml-0 pl-0">Response Options</v-subheader>
          <v-list v-if="v.responses.length" dense style="margin-top: -20px;">
            <v-list-item :key="rInd" v-for="(res, rInd) in v.responses" style="min-height: 28px; padding-right: 14px;">
              <v-list-item-content class="py-0">
                <v-list-item-title class="d-flex">
                  <div style="width: 50%; line-height: 22px;">{{ res.label }}</div>
                  <v-icon class="mr-5">mdi-arrow-right</v-icon>
                  <div class="d-flex" v-if="res.next" style="width: 40%;">
                    {{ inputLabel }}
                    {{ getAdjustedLabel(...getChildIndex(v.id, res.next)) }}
                  </div>
                  <div class="d-flex" v-if="!res.next" style="width: 40%; line-height: 22px;">
                    Next Question
                  </div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="my-0" v-if="editMode === v.id">
                <v-btn icon @click="removeResponse(v, rInd)">
                  <v-icon color="grey">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="d-flex" v-if="editMode === v.id">
            <v-text-field
              style="width: 40%;"
              class="mr-3"
              type="text"
              label="Add Response Option"
              v-model="v.responseModel.label"
              hint="'Agree', 'Disagree'"
              outlined
              dense>
            </v-text-field>
            <v-icon class="mb-6 mr-2">mdi-arrow-right</v-icon>
            <v-select
              class="mr-5"
              outlined
              style="width: 40%;"
              dense
              label="Following Question"
              append-outer-icon="mdi-plus-circle"
              v-model="v.responseModel.next"
              @click:append-outer="addResponse(v.id)"
              item-text="value"
              item-value="id"
              :items="getValidQuestions(v.id)">
            </v-select>
          </div>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-btn
            text
            @click="addItem(100 + index, v.id)"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <v-icon class="mr-1">
              mdi-arrow-right-bottom
            </v-icon>Add Child {{ inputLabel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        class="px-1 rounded-lg elevation-2 mb-3 ml-7"
        v-for="(c, ind) in v.children"
        :key="`item-${c.id}`">
        <v-card-title>
          <span>{{ getAdjustedLabel(index, ind) }}</span>
          <v-spacer/>
          <v-btn
            icon
            text
            @click="removeItemConfirm(c.id)"
            :class="{'black--text': branding.primaryButtons.darkText}"
            class="mr-2"
            v-bind="branding.primaryButtons">
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            v-if="editMode !== c.id"
            @click="editMode = c.id;"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            v-if="editMode === c.id"
            @click="editMode = -1"
            :class="{'black--text': branding.primaryButtons.darkText}"
            v-bind="branding.primaryButtons">
            <v-icon>
              mdi-content-save-outline
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="editMode !== c.id && !c.value" class="text-body-1"><i>Add your Question Content</i></div>
          <div v-if="editMode !== c.id" class="text-body-1 mb-4">{{c.value}}</div>
          <!-- <div class="mt-3" v-if="editMode !== index && questionPostPend">{{questionPostPend}}</div> -->
          <v-textarea
            v-if="editMode === c.id"
            v-model="c.value"
            :label="inputLabel"
            :error-messages="getErrors('itemcontent', c.value)"
            required
            outlined
            rows="1"
            hint="Pose your question without the prompts to respond"
            counter
            dense>
          </v-textarea>
          <v-subheader v-if="c.responses.length || editMode === c.id" class="ml-0 pl-0">Response Options</v-subheader>
          <v-list v-if="c.responses.length" dense style="margin-top: -20px;">
            <v-list-item :key="rInd" v-for="(res, rInd) in c.responses" style="min-height: 28px; padding-right: 14px;">
              <v-list-item-content class="py-0">
                <v-list-item-title class="d-flex">
                  <div style="width: 60%; line-height: 20px;">{{ res.label }}</div>
                  <v-icon class="mr-5">mdi-arrow-right</v-icon>
                  <div class="d-flex" v-if="res.next" style="width: 40%; line-height: 20px;">
                    {{ inputLabel }}
                    {{ getAdjustedLabel(parentsAsArray.findIndex((p) => p.id === res.next)) }}
                  </div>
                  <div class="d-flex" v-if="!res.next" style="width: 40%; line-height: 20px;">
                    Next Question
                  </div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="my-0" v-if="editMode === c.id">
                <v-btn icon @click="removeResponse(c, rInd)">
                  <v-icon color="grey">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="d-flex" v-if="editMode === c.id">
            <v-text-field
              style="width: 40%;"
              class="mr-3"
              type="text"
              label="Add Response Option"
              v-model="c.responseModel.label"
              hint="E.g. 'Agree', 'Disagree', 'Labor', 'Liberal', 'Greens'"
              outlined
              dense>
            </v-text-field>
            <v-icon class="mb-6 mr-2">mdi-arrow-right</v-icon>
            <v-select
              outlined
              dense
              style="width: 40%;"
              label="Following Question"
              append-outer-icon="mdi-plus-circle"
              v-model="c.responseModel.next"
              @click:append-outer="addResponse(c.id)"
              item-text="value"
              item-value="id"
              :items="getValidQuestions(c.id)">
            </v-select>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="d-flex mt-3">
      <v-spacer/>
      <v-btn
        outlined
        @click="addItem((parentsAsArray.length))"
        :class="{'black--text': branding.primaryButtons.darkText}"
        v-bind="branding.primaryButtons">
        <v-icon class="mr-1">
          mdi-plus
        </v-icon>Add {{ inputLabel }}
      </v-btn>
    </div>
    <v-dialog
      v-model="deleteDialog"
      transition="dialog-bottom-transition"
      width="400"
      scrollable
      persistent>
      <v-card>
        <v-card-title>
          Are you sure you want to delete this Question?
        </v-card-title>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn
          @click="deleteDialog = false; removeItem()"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Confirm
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </template>

<script>
import { validationMixin } from 'vuelidate';
import cloneDeep from 'lodash/cloneDeep';
import { genTempID } from '../utils';

export default {
  name: 'InputList',
  mixins: [validationMixin],
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: String,
      default: () => 'Please enter the code provided',
    },
    icon: {
      type: String,
      default: () => 'mdi-check-circle',
    },
    inputType: {
      type: String,
      default: () => 'text',
    },
    inputLabel: {
      type: String,
      default: () => 'Item',
    },
    inputSampleSize: {
      type: [Number, String],
      default: 0,
    },
    questionPostPend: {
      type: String,
      default: () => '[ ] Yes    [ ] No',
    },
    surveyAnswerPromptDefault: {
      type: String,
      default: () => '',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    maxSampleSize: {
      type: Number,
      default: 0,
    },
    addCheckbox: {
      type: Boolean,
      default: () => false,
    },
    checkboxLabel: {
      type: String,
      default: () => 'Please check',
    },
    itemOptions: {
      type: Array,
      default: () => [],
    },
    defaultLastQuestion: {
      type: String,
      default: () => '',
    },
    reason: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      localItems: {},
      form: {
        value: null,
      },
      editMode: 0,
      removeId: null,
      deleteDialog: false,
    };
  },
  mounted() {
    if (Object.keys(this.items).length) {
      this.localItems = cloneDeep(this.items);
      return;
    }

    this.editMode = this.addItem(0);
  },
  computed: {
    listIsValid() {
      return this.parentsAsArray.length && this.parentsAsArray[0].value && this.parentsAsArray[0].responses.length;
    },
    parentsAsArray() {
      return Object.values(this.localItems).filter((x) => !x.parent).sort((a, b) => a.order - b.order).map((p) => ({
        ...p,
        children: Object.values(this.localItems).filter((y) => y.parent && y.parent === p.id).sort((a, b) => a.order - b.order),
      }));
    },
  },
  methods: {
    getChildIndex(parentId, otherId) {
      const parentIndex = this.parentsAsArray.findIndex((f) => f.id === parentId);
      const childIndex = parentIndex !== -1 ? this.parentsAsArray[parentIndex].children.findIndex((c) => c.id === otherId) : -1;
      if (childIndex !== -1) return [parentIndex, childIndex];
      return [this.parentsAsArray.findIndex((f) => f.id === otherId)];
    },
    getAdjustedLabel(parentIndex, childIndex) {
      console.log(parentIndex, childIndex);
      if (!childIndex && childIndex !== 0) return parentIndex + 1;

      return `${parentIndex + 1}${this.getLetter(childIndex)}`;
    },
    getValidQuestions(id) {
      const firstOrderElements = [];
      const parentIndex = this.parentsAsArray.findIndex((p) => p.id === id);

      this.parentsAsArray.forEach((f, i) => {
        if (f.id === id) return;
        firstOrderElements.push({
          id: f.id,
          value: `Question ${this.getAdjustedLabel(i)}`,
        });
      });

      if (parentIndex !== -1) {
        this.parentsAsArray[parentIndex].children.forEach((f, i) => {
          firstOrderElements.push({
            id: f.id,
            value: `Question ${this.getAdjustedLabel(parentIndex, i)}`,
          });
        });
      }

      firstOrderElements.push({
        value: '-- Next Question --',
        id: null,
      });

      console.log(firstOrderElements);

      return firstOrderElements;
    },
    getLetter(index) {
      console.log(index);
      return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'][index];
    },
    validate() {
      this.$v.$touch();
      if (this.$v.invalid) {
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors;
      switch (name) {
        case 'itemcontent':
          if (!model.required) {
            errors.push(`${this.inputLabel} is required`);
          }
          break;
        case 'samplesize':
          // TODO: This check could potentially be removed.
          if (this.maxSampleSize) {
            if (!model.required) {
              errors.push('Sample size is required');
            }
            if (!model.valid) {
              errors.push(`Sample size must be between 1 and ${this.maxSampleSize}`);
            }
            if (!model.integer) {
              errors.push('Sample size must be an integer');
            }
          }
          break;
        default:
          break;
      }
      return errors;
    },
    addItem(index, parent = null) {
      console.log(index);
      const id = genTempID(4);
      this.$set(this.localItems, id, {
        value: '',
        responses: [],
        id,
        order: index,
        parent,
        responseModel: {
          label: '',
          index: null,
        },
        children: [],
      });

      return id;
    },
    removeItemConfirm(id) {
      this.removeId = id;
      this.deleteDialog = true;
    },
    removeItem() {
      this.$delete(this.localItems, this.removeId);
    },
    removeResponse(item, index) {
      item.responses.splice(index, 1);
    },
    addResponse(id) {
      this.localItems[id].responses.push(cloneDeep(this.localItems[id].responseModel));

      this.$set(this.localItems[id], 'responseModel', {
        label: '',
        next: null,
      });
    },
    submit() {
      if (this.defaultLastQuestion) {
        this.$set(this.localItems, genTempID(4), ({
          value: this.defaultLastQuestion,
          responses: [],
          responseModel: '',
        }));
      }

      const responseComposer = (responses) => {
        const strArr = responses.map((res, i) => `Reply ${i + 1} for ${res.label}`);

        return `\n\n${strArr.join('\n')}`;
      };

      const payload = {
        listCompatible: Object.values(this.localItems).map((val, i) => ({
          title: `Question ${i + 1}`,
          subtitle: `${val.value.length > 60
            ? `${val.value.slice(0, 50)}...`
            : val.value}`,
        })),
        simpleArray: Object.values(this.localItems)
          .map((val) => `Q${this.getAdjustedLabel(...this.getChildIndex(val.parent, val.id))}: ${val.value.length > 60
            ? `${val.value.slice(0, 50)}'...`
            : val.value}`),
        asString: Object.values(this.localItems).map((val, i) => `Question ${i + 1}: ${val.value}`).join('</br></br>'),
        items: this.parentsAsArray,
        componentCompatible: this.localItems,
        survey: Object.values(this.localItems).reduce((acc, cv) => {
          acc[cv.id] = {
            prompt: `${cv.value}${cv.responses.length ? responseComposer(cv.responses) : ''}`,
            invalidResponseMaxAttempts: 3,
            order: cv.order,
            responses: cv.responses.reduce((acc1, cv1, i) => {
              const accx = acc1;
              accx[cv1.label.toLowerCase()] = {
                keywords: [`${i + 1}`],
                introAudioFile: null,
                unrecognisedResponseText: null,
                unrecognisedResponseAudioFile: null,
                invalidResponseMaxAttempts: null,
                responseTimeout: 7500,
                timeoutResponse: null,
                defaultResponse: null,
                satisfiesCompletion: null,
                nextQuestion: cv1.next ? cv1.next : 'next',
                order: i,
              };
              return accx;
            }, {}),
          };

          return acc;
        }, {}),
        displayValues: Object.values(this.localItems).map((item) => `<li>
          ${item.value}</br></br>${this.questionPostPend}
        </li>`).join(''),
      };
      this.$emit('trigger', {
        form: payload,
        instanceKey: this.instanceKey,
        reason: this.reason,
        friendly: `${Object.keys(payload.items).length} selected`,
      });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
