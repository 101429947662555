<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2">
      <div v-if="title" class="ml-5 pt-5 mb-3 font-weight-bold">
        {{ title }}
      </div>
      <v-card-text class="px-5">
        <div v-for="(item, index) in items" :key="index">
          <v-text-field
            v-if="item.inputType !== 'textarea'"
            :type="item.inputType"
            v-model="$v.form[item.name].$model"
            :label="item.inputLabel"
            required
            outlined
            dense>
          </v-text-field>
          <v-textarea
            v-if="item.inputType === 'textarea'"
            :type="item.inputType"
            v-model="$v.form[item.name].$model"
            :label="item.inputLabel"
            required
            outlined
            dense>
          </v-textarea>
        </div>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="22" class="ml-2 mt-1">
              mdi-arrow-right-circle
            </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate';

import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'InputMultiForm',
  mixins: [validationMixin],
  props: {
    error: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => 'Enter your ',
    },
    items: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: () => 'Please enter the code provided',
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
    reason: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    // valueErrors() {
    //   const errors = [];
    //   if (!this.$v.form.value.$dirty) return errors;
    //   if (this.$v.form.value.$error) {
    //     errors.push('Please provide a value');
    //   }
    //   return errors;
    // },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: {
        value: null,
      },
    };
  },
  validations() {
    return {
      form: this.generateValidations(),
    };
  },
  methods: {
    generateModel() {
      return this.items.reduce((acc, cv) => {
        acc[cv.name] = null;

        return acc;
      }, {});
    },
    generateValidations() {
      return this.items.reduce((acc, cv) => {
        acc[cv.name] = {};

        if (cv.validations && cv.validations.required) {
          acc[cv.name].required = required;
        }

        return acc;
      }, {});
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('error', 'invalid');
        return;
      }

      this.$emit('trigger', {
        form: this.form,
        instanceKey: this.instanceKey,
        reason: this.reason,
      });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
  mounted() {
    this.form = this.generateModel();
  },
};
</script>
