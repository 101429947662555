<template>
<div class="">
  <v-stepper alt-labels
    :elevation="0"
    class="mt-0 pt-0"
    style="background-color: initial; margin-top: -20px !important;"
    :value="currentStep">
    <v-stepper-header>
      <template v-for="(item, index) in items">
        <v-stepper-step :step="index" :key="index">
          {{item.text}}
        </v-stepper-step>

        <v-divider :key="index" v-if="index < items.length -1"></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</div>
</template>

<script>

export default {
  name: 'Stepper',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
    };
  },
  methods: {
    submit() {
      this.$emit('trigger');
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
};
</script>
