export default {
  prod: {
    url: 'https://*.w.yabbr.io',
    port: '',
    dms: 'https://dms.yabbr.io/2022-05-31',
  },
  dev: {
    url: 'https://*.w.dev.yabbr.io',
    port: '',
    dms: 'https://dms.dev.yabbr.io/2022-05-31',
  },
  local: {
    url: 'http://localhost:8080',
    port: '8080',
    dms: 'https://dms.dev.yabbr.io/2022-05-31',
  },
};
