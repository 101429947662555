<template>
<div class="">
  <form
    novalidate
    @submit.prevent="submit">
    <v-card class="px-1 rounded-lg elevation-2 mb-5">
      <v-card-text class="px-8 pt-5">
        <v-select
          v-model="form"
          :label="title"
          :items="selectItems"
          item-text="text"
          item-value="value"
          @change="onChange" />
      </v-card-text>
      <v-card-actions class="px-8 pb-6" v-if="!hideCardActions">
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          class="font-weight-bold"
          :class="{'black--text': branding.primaryButtons.darkText}"
          v-bind="branding.primaryButtons">
          Continue
          <v-icon size="22" class="ml-2 mt-1">
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</div>
</template>

<script>

export default {
  name: 'InputMultiselectMergedList',
  props: {
    title: {
      type: String,
      default: '',
    },
    valueKey: {
      type: String,
      default: '',
    },
    itemValues: {
      type: Object,
      default: () => ({}),
    },
    listDefinitions: {
      type: Array,
      default: () => ([]),
    },
    valueFilterPattern: {
      type: String,
      default: '',
    },
    hideCardActions: {
      type: Boolean,
      default: false,
    },
    instanceKey: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ...this.mapState('branding'),
      form: '',
    };
  },
  computed: {
    selectItems() {
      return Object.entries(this.itemValues).reduce((acc, [key, val]) => {
        if (!val) return acc;
        if (this.valueFilterPattern && !(new RegExp(this.valueFilterPattern).test(val))) return acc;

        const findLabel = this.listDefinitions.find((el) => el.name === key);
        if (!findLabel) return acc;

        acc.push({
          text: `${findLabel.label || ''}: ${val}`,
          value: val,
        });

        return acc;
      }, []).sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });
    },
  },
  methods: {
    onChange(e) {
      this.$emit('store-local-body', { [this.valueKey]: e });
    },
    submit() {
      this.$emit('trigger', { [this.valueKey]: this.form, instanceKey: this.instanceKey });
      this.$emit('error', null);
      this.$emit('close-dialog');
    },
  },
  mounted() {
    const firstPhone = this.selectItems.find((el) => el.text.toLowerCase().includes('phone'));
    if (firstPhone) {
      this.form = firstPhone.value;
      this.onChange(firstPhone.value);
    }
  },
};
</script>
